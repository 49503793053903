import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  useTheme,
  useMediaQuery,
  Container,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Stack,
  Slider,
  Divider,
  Popover,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import { useSelectedChatbot } from '../context/SelectedChatbotContext';
import { ChromePicker } from 'react-color';
import SaveIcon from '@mui/icons-material/Save';
import { getChatbotConfig, saveChatbotConfig } from '../services/api';

const Settings = () => {
  const iframeRef = useRef(null);
  const { selectedChatbot } = useSelectedChatbot();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Estado inicial con placeholders por defecto
  const defaultConfig = {
    TITULO_NOM: 'Asistente IA 4LogicONE',
    PRIMER_MENSAJE: 'Hola, soy 4LogicONE, asistente de AI4Logic, ¿En qué puedo ayudarte?',
    CLIENTE_EMAIL: 'tuemail@ai4logic.com',
    CLIENTE_EMAIL_NOM: 'Tu nombre',
    CLIENTE_WEB: 'https://tuweb.com/',
    NOMBRE: 'AI4Logic web',
    TITULO_ICO: 'logo1',
    TITULO_ICO_URL: '',
    STYLE_sizebutton: 60,
    STYLE_animation: true,
    STYLE_openanimation: true,
    STYLE_firstrunopen: false,
    buttoncolor1: '#ff0080',
    buttoncolor2: '#ff8c00',
    STYLE_position_marginX: 20,
    STYLE_position_marginY: 20
  };

  const [pendingConfig, setPendingConfig] = useState(defaultConfig);
  const [savedConfig, setSavedConfig] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [colorAnchor1, setColorAnchor1] = useState(null);
  const [colorAnchor2, setColorAnchor2] = useState(null);
  const [iconMenuAnchor, setIconMenuAnchor] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);

  // Cargar datos del chatbot al iniciar
  useEffect(() => {
    const fetchChatbotData = async () => {
      if (!selectedChatbot) return;
      
      try {
        const data = await getChatbotConfig(selectedChatbot);
        
        if (data) {
          // Convertir la estructura de la API a nuestro formato
          const configFromApi = {
            TITULO_NOM: data.title_name || defaultConfig.TITULO_NOM,
            PRIMER_MENSAJE: data.welcome || defaultConfig.PRIMER_MENSAJE,
            CLIENTE_EMAIL: data.client_email || defaultConfig.CLIENTE_EMAIL,
            CLIENTE_EMAIL_NOM: data.client_emailname || defaultConfig.CLIENTE_EMAIL_NOM,
            CLIENTE_WEB: data.client_web || defaultConfig.CLIENTE_WEB,
            NOMBRE: data.name || defaultConfig.NOMBRE,
            TITULO_ICO: data.title_icon ? 'custom' : 'logo1',
            TITULO_ICO_URL: data.title_icon || '',
            STYLE_sizebutton: parseInt(data.styles?.sizebutton) || defaultConfig.STYLE_sizebutton,
            STYLE_animation: data.styles?.animation === 'true' || defaultConfig.STYLE_animation,
            STYLE_openanimation: data.styles?.openanimation === 'true' || defaultConfig.STYLE_openanimation,
            STYLE_firstrunopen: data.styles?.firstrunopen === 'true' || defaultConfig.STYLE_firstrunopen,
            buttoncolor1: data.styles?.buttoncolor1 || defaultConfig.buttoncolor1,
            buttoncolor2: data.styles?.buttoncolor2 || defaultConfig.buttoncolor2,
            STYLE_position_marginX: parseInt(data.styles?.position_marginX) || defaultConfig.STYLE_position_marginX,
            STYLE_position_marginY: parseInt(data.styles?.position_marginY) || defaultConfig.STYLE_position_marginY
          };
          
          setPendingConfig(configFromApi);
          setSavedConfig(configFromApi);
        } else {
          // Si no hay datos, usar los valores por defecto
          setPendingConfig(defaultConfig);
          setSavedConfig(defaultConfig);
        }
      } catch (error) {
        console.error('Error al obtener datos del chatbot:', error);
        // En caso de error, usar los valores por defecto
        setPendingConfig(defaultConfig);
        setSavedConfig(defaultConfig);
      } finally {
        setIsLoading(false);
      }
    };

    fetchChatbotData();
  }, [selectedChatbot]);

  const handleChange = (field, value) => {
    setPendingConfig(prev => ({ ...prev, [field]: value }));
    setHasChanges(true);
  };

  const handleSave = async () => {
    if (!selectedChatbot) return;

    try {
      // Convertir nuestro formato al formato de la API
      const configForApi = {
        title_name: pendingConfig.TITULO_NOM,
        welcome: pendingConfig.PRIMER_MENSAJE,
        client_email: pendingConfig.CLIENTE_EMAIL,
        client_emailname: pendingConfig.CLIENTE_EMAIL_NOM,
        client_web: pendingConfig.CLIENTE_WEB,
        name: pendingConfig.NOMBRE,
        title_icon: pendingConfig.TITULO_ICO === 'custom' 
          ? pendingConfig.TITULO_ICO_URL 
          : `https://ai4logic.com/${pendingConfig.TITULO_ICO}.png`,
        styles: {
          sizebutton: String(pendingConfig.STYLE_sizebutton),
          animation: String(pendingConfig.STYLE_animation),
          openanimation: String(pendingConfig.STYLE_openanimation),
          firstrunopen: String(pendingConfig.STYLE_firstrunopen),
          buttoncolor1: pendingConfig.buttoncolor1,
          buttoncolor2: pendingConfig.buttoncolor2,
          position_marginX: String(pendingConfig.STYLE_position_marginX),
          position_marginY: String(pendingConfig.STYLE_position_marginY)
        }
      };

      await saveChatbotConfig(selectedChatbot, configForApi);
      setSavedConfig(pendingConfig);
      setHasChanges(false);
    } catch (error) {
      console.error('Error al guardar los cambios:', error);
    }
  };

  useEffect(() => {
    if (!savedConfig) {
      setSavedConfig(pendingConfig);
      return;
    }

    if (iframeRef.current && selectedChatbot) {
      const iframeDoc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
      
      // Crear el objeto de configuración que espera el chatbot
      const chatbotConfig = {
        welcome: savedConfig.PRIMER_MENSAJE || '',
        title_icon: savedConfig.TITULO_ICO === 'custom' 
          ? (savedConfig.TITULO_ICO_URL || '') 
          : `https://ai4logic.com/${savedConfig.TITULO_ICO || 'logo1'}.png`,
        title_name: savedConfig.TITULO_NOM || '',
        styles: {
          position_marginX: String(savedConfig.STYLE_position_marginX || 20),
          position_marginY: String(savedConfig.STYLE_position_marginY || 20),
          openanimation: String(savedConfig.STYLE_openanimation || true),
          firstrunopen: String(savedConfig.STYLE_firstrunopen || false),
          buttoncolor1: savedConfig.buttoncolor1 || '#ff0080',
          buttoncolor2: savedConfig.buttoncolor2 || '#ff8c00',
          animation: String(savedConfig.STYLE_animation || false),
          sizebutton: String(savedConfig.STYLE_sizebutton || 60)
        },
        commands: {
          reset_cookies: true
        }
      };
      
      iframeDoc.open();
      iframeDoc.write(`
        <!DOCTYPE html>
        <html>
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <script src="https://cdn.jsdelivr.net/npm/axios/dist/axios.min.js"></script>
            <script src="https://kit.fontawesome.com/e14478f995.js" crossorigin="anonymous"></script>
            <style>
              body { margin: 0; padding: 0; height: 100vh; overflow: hidden; background-color: #0b151b; }
              #chatbot { height: 100%; width: 100%; }
            </style>
          </head>
          <body>
            <div id="chatbot"></div>
            <script>
              var CLIENTE = "${selectedChatbot}";
              var RUTA = "https://overlord.chat";
              
              // En lugar de hacer fetch, usamos directamente la configuración
              (function() {
                var script = document.createElement('script');
                script.src = RUTA + '/chatbot-widget.js';
                script.async = true;
                script.onload = function() {
                  const data = ${JSON.stringify(chatbotConfig)};
                  new Chatbot(document.getElementById('chatbot'), "${selectedChatbot}", data.welcome, data, true);
                };
                document.head.appendChild(script);
              })();
            </script>
          </body>
        </html>
      `);
      iframeDoc.close();
    }
  }, [selectedChatbot, savedConfig]);

  const textFieldStyle = {
    '& .MuiOutlinedInput-root': {
      color: '#ecf2f6',
      '& fieldset': { borderColor: '#205a7b' },
      '&:hover fieldset': { borderColor: '#4aa6da' },
    },
    '& .MuiInputLabel-root': { color: '#96c3dd' }
  };

  const renderSection = (title, children) => (
    <Box sx={{ mb: 2 }}>
      <Box 
        sx={{ 
          mb: 1,
          p: 1.5,
          borderLeft: '4px solid #4aa6da',
          backgroundColor: 'rgba(74, 166, 218, 0.1)',
          borderRadius: '0 4px 4px 0'
        }}
      >
        <Typography 
          variant="h6" 
          sx={{ 
            color: '#ffffff',
            fontFamily: 'FSSinclair, sans-serif',
            display: 'flex',
            alignItems: 'center',
            '&::before': {
              content: '""',
              width: '8px',
              height: '8px',
              backgroundColor: '#4aa6da',
              borderRadius: '50%',
              marginRight: '12px'
            }
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box sx={{ px: 2 }}>
        {children}
      </Box>
    </Box>
  );

  return (
    <Container maxWidth={false}>
      <Box sx={{ p: 0, position: 'relative', minHeight: '100vh' }}>
        <Paper
          elevation={3}
          sx={{
            backgroundColor: '#0b151b',
            border: '1px solid #205a7b',
            borderRadius: 2,
            p: 2,
            mb: 2
          }}
        >
          <Typography variant="h4" sx={{ color: '#ffffff', mb: 0, fontFamily: 'FSSinclair, sans-serif' }}>
            Parámetros
          </Typography>
          <Typography variant="body1" sx={{ color: '#96c3dd', fontFamily: 'FSSinclair, sans-serif' }}>
            Configura los parámetros del sistema y observa los cambios en tiempo real.
          </Typography>
        </Paper>

        <Grid container spacing={2} sx={{ flexDirection: { xs: 'column-reverse', md: 'row' } }}>
          {/* Columna izquierda - Parámetros */}
          <Grid item xs={12} md={8}>
            <Paper
              elevation={3}
              sx={{
                backgroundColor: '#0b151b',
                border: '1px solid #205a7b',
                borderRadius: 2,
                p: 2,
                height: { xs: '60vh', md: '80vh' },
                overflowY: 'auto',
                position: 'relative'
              }}
            >
              {/* Información General */}
              {renderSection('Información General',
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Typography sx={{ 
                          color: '#96c3dd', 
                          mb: 1,
                          fontFamily: 'FSSinclair, sans-serif',
                          fontWeight: 600
                        }}>Nombre del Asistente</Typography>
                        <TextField
                          value={pendingConfig.TITULO_NOM}
                          onChange={(e) => handleChange('TITULO_NOM', e.target.value)}
                          fullWidth
                          sx={textFieldStyle}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography sx={{ 
                          color: '#96c3dd', 
                          mb: 1,
                          fontFamily: 'FSSinclair, sans-serif',
                          fontWeight: 600
                        }}>Icono del Chatbot</Typography>
                        <Button
                          onClick={(e) => setIconMenuAnchor(e.currentTarget)}
                          sx={{
                            backgroundColor: '#162d3a',
                            color: '#ecf2f6',
                            border: '1px solid #205a7b',
                            '&:hover': {
                              backgroundColor: '#1a3a4a',
                              borderColor: '#4aa6da',
                            },
                            minWidth: '200px',
                            justifyContent: 'flex-start',
                            gap: 2,
                            px: 2,
                            height: '56px',
                            width: '100%'
                          }}
                        >
                          <Box
                            component="img"
                            src={pendingConfig.TITULO_ICO === 'custom' 
                              ? pendingConfig.TITULO_ICO_URL 
                              : `https://ai4logic.com/${pendingConfig.TITULO_ICO}.png`}
                            alt="Icono seleccionado"
                            sx={{
                              width: '32px',
                              height: '32px',
                              objectFit: 'contain'
                            }}
                            onError={(e) => {
                              e.target.src = 'https://ai4logic.com/logo1.png';
                            }}
                          />
                          {pendingConfig.TITULO_ICO === 'custom' ? 'URL Personalizada' : 
                            pendingConfig.TITULO_ICO === 'logo1' ? 'Default' :
                            pendingConfig.TITULO_ICO === 'logoia1' ? 'Asistente' : 'Robot'}
                        </Button>
                        <Popover
                          open={Boolean(iconMenuAnchor)}
                          anchorEl={iconMenuAnchor}
                          onClose={() => setIconMenuAnchor(null)}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          PaperProps={{
                            sx: {
                              backgroundColor: '#0b151b',
                              border: '1px solid #205a7b',
                              mt: 1
                            }
                          }}
                        >
                          <Box sx={{ p: 1, width: '250px' }}>
                            <Stack spacing={1}>
                              {[
                                { value: 'logo1', label: 'Default', description: 'Icono predeterminado' },
                                { value: 'logoia1', label: 'Asistente', description: 'Icono de asistente IA' },
                                { value: 'logoia2', label: 'Robot', description: 'Icono de robot IA' }
                              ].map((icon) => (
                                <Paper
                                  key={icon.value}
                                  onClick={() => {
                                    handleChange('TITULO_ICO', icon.value);
                                    handleChange('TITULO_ICO_URL', '');
                                    setIconMenuAnchor(null);
                                  }}
                                  sx={{
                                    p: 1.5,
                                    cursor: 'pointer',
                                    backgroundColor: pendingConfig.TITULO_ICO === icon.value ? '#162d3a' : '#0b151b',
                                    border: '1px solid',
                                    borderColor: pendingConfig.TITULO_ICO === icon.value ? '#4aa6da' : '#205a7b',
                                    '&:hover': {
                                      backgroundColor: '#162d3a',
                                      borderColor: '#4aa6da',
                                    },
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2
                                  }}
                                >
                                  <Box
                                    component="img"
                                    src={`https://ai4logic.com/${icon.value}.png`}
                                    alt={icon.label}
                                    sx={{
                                      width: '40px',
                                      height: '40px',
                                      objectFit: 'contain'
                                    }}
                                  />
                                  <Box>
                                    <Typography sx={{ color: '#ecf2f6', fontSize: '0.875rem', fontWeight: 500 }}>
                                      {icon.label}
                                    </Typography>
                                    <Typography sx={{ color: '#96c3dd', fontSize: '0.75rem' }}>
                                      {icon.description}
                                    </Typography>
                                  </Box>
                                </Paper>
                              ))}
                              <Paper
                                onClick={() => {
                                  if (pendingConfig.TITULO_ICO !== 'custom') {
                                    handleChange('TITULO_ICO', 'custom');
                                  }
                                }}
                                sx={{
                                  p: 1.5,
                                  cursor: 'pointer',
                                  backgroundColor: pendingConfig.TITULO_ICO === 'custom' ? '#162d3a' : '#0b151b',
                                  border: '1px solid',
                                  borderColor: pendingConfig.TITULO_ICO === 'custom' ? '#4aa6da' : '#205a7b',
                                  '&:hover': {
                                    backgroundColor: '#162d3a',
                                    borderColor: '#4aa6da',
                                  }
                                }}
                              >
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: pendingConfig.TITULO_ICO === 'custom' ? 1 : 0 }}>
                                  <Box
                                    sx={{
                                      width: '40px',
                                      height: '40px',
                                      borderRadius: '50%',
                                      backgroundColor: '#205a7b',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      color: '#ecf2f6',
                                      fontSize: '24px'
                                    }}
                                  >
                                    +
                                  </Box>
                                  <Box>
                                    <Typography sx={{ color: '#ecf2f6', fontSize: '0.875rem', fontWeight: 500 }}>
                                      URL Personalizada
                                    </Typography>
                                    <Typography sx={{ color: '#96c3dd', fontSize: '0.75rem' }}>
                                      Usar un icono personalizado
                                    </Typography>
                                  </Box>
                                </Box>
                                {pendingConfig.TITULO_ICO === 'custom' && (
                                  <TextField
                                    size="small"
                                    value={pendingConfig.TITULO_ICO_URL}
                                    onChange={(e) => handleChange('TITULO_ICO_URL', e.target.value)}
                                    placeholder="https://ejemplo.com/imagen.png"
                                    fullWidth
                                    sx={{
                                      mt: 1,
                                      '& .MuiOutlinedInput-root': {
                                        color: '#ecf2f6',
                                        '& fieldset': { borderColor: '#205a7b' },
                                        '&:hover fieldset': { borderColor: '#4aa6da' },
                                        backgroundColor: '#0b151b'
                                      }
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                  />
                                )}
                              </Paper>
                            </Stack>
                          </Box>
                        </Popover>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography sx={{ 
                          color: '#96c3dd', 
                          mb: 1,
                          fontFamily: 'FSSinclair, sans-serif',
                          fontWeight: 600
                        }}>Primer Mensaje</Typography>
                        <TextField
                          value={pendingConfig.PRIMER_MENSAJE}
                          onChange={(e) => handleChange('PRIMER_MENSAJE', e.target.value)}
                          multiline
                          rows={2}
                          fullWidth
                          sx={textFieldStyle}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  
                </Grid>
              )}

              {renderSection('Datos de Contacto',
                <>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={6}>
                      <Typography sx={{ 
                        color: '#96c3dd', 
                        mb: 1,
                        fontFamily: 'FSSinclair, sans-serif',
                        fontWeight: 600
                      }}>Email de Contacto</Typography>
                      <TextField
                        value={pendingConfig.CLIENTE_EMAIL}
                        onChange={(e) => handleChange('CLIENTE_EMAIL', e.target.value)}
                        fullWidth
                        sx={textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography sx={{ 
                        color: '#96c3dd', 
                        mb: 1,
                        fontFamily: 'FSSinclair, sans-serif',
                        fontWeight: 600
                      }}>Nombre de Contacto</Typography>
                      <TextField
                        value={pendingConfig.CLIENTE_EMAIL_NOM}
                        onChange={(e) => handleChange('CLIENTE_EMAIL_NOM', e.target.value)}
                        fullWidth
                        sx={textFieldStyle}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ 
                      color: '#96c3dd', 
                      mb: 1,
                      mt: 1,
                      fontFamily: 'FSSinclair, sans-serif',
                      fontWeight: 600
                    }}>Sitio Web</Typography>
                    <TextField
                      value={pendingConfig.CLIENTE_WEB}
                      onChange={(e) => handleChange('CLIENTE_WEB', e.target.value)}
                      fullWidth
                      sx={textFieldStyle}
                    />
                  </Grid>
                </>
              )}

              {/* Estilos y Animaciones */}
              {renderSection('Estilos y Animaciones',
                <>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={6}>
                      <Typography sx={{ 
                        color: '#96c3dd', 
                        mb: 1,
                        fontFamily: 'FSSinclair, sans-serif',
                        fontWeight: 600
                      }}>Tamaño del botón</Typography>
                      <Slider
                        value={pendingConfig.STYLE_sizebutton}
                        onChange={(e, value) => handleChange('STYLE_sizebutton', value)}
                        min={40}
                        max={120}
                        step={1}
                        valueLabelDisplay="auto"
                        sx={{
                          color: '#4aa6da',
                          '& .MuiSlider-thumb': {
                            backgroundColor: '#ecf2f6',
                          },
                          '& .MuiSlider-rail': {
                            backgroundColor: '#205a7b',
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography sx={{ 
                        color: '#96c3dd', 
                        mb: 1,
                        fontFamily: 'FSSinclair, sans-serif',
                        fontWeight: 600
                      }}>Margen Horizontal (X)</Typography>
                      <Slider
                        value={pendingConfig.STYLE_position_marginX}
                        onChange={(e, value) => handleChange('STYLE_position_marginX', value)}
                        min={0}
                        max={100}
                        step={1}
                        valueLabelDisplay="auto"
                        sx={{
                          color: '#4aa6da',
                          '& .MuiSlider-thumb': {
                            backgroundColor: '#ecf2f6',
                          },
                          '& .MuiSlider-rail': {
                            backgroundColor: '#205a7b',
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography sx={{ 
                        color: '#96c3dd', 
                        mb: 1,
                        fontFamily: 'FSSinclair, sans-serif',
                        fontWeight: 600
                      }}>Margen Vertical (Y)</Typography>
                      <Slider
                        value={pendingConfig.STYLE_position_marginY}
                        onChange={(e, value) => handleChange('STYLE_position_marginY', value)}
                        min={0}
                        max={100}
                        step={1}
                        valueLabelDisplay="auto"
                        sx={{
                          color: '#4aa6da',
                          '& .MuiSlider-thumb': {
                            backgroundColor: '#ecf2f6',
                          },
                          '& .MuiSlider-rail': {
                            backgroundColor: '#205a7b',
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack direction="row" spacing={2} sx={{ 
                        justifyContent: 'center'
                      }}>
                        <Box sx={{ textAlign: 'center' }}>
                          <Typography sx={{ 
                            color: '#96c3dd', 
                            mb: 1,
                            fontFamily: 'FSSinclair, sans-serif',
                            fontWeight: 600,
                            textAlign: 'center'
                          }}>Color Superior</Typography>
                          <Button
                            onClick={(e) => setColorAnchor1(e.currentTarget)}
                            sx={{
                              minWidth: 'auto',
                              p: 0,
                              border: '2px solid #205a7b',
                              borderRadius: 1,
                              overflow: 'hidden',
                              display: 'inline-flex',
                              '&:hover': {
                                border: '2px solid #4aa6da',
                              }
                            }}
                          >
                            <Box
                              sx={{
                                backgroundColor: pendingConfig.buttoncolor1,
                                width: '36px',
                                height: '36px',
                              }}
                            />
                          </Button>
                          <Popover
                            open={Boolean(colorAnchor1)}
                            anchorEl={colorAnchor1}
                            onClose={() => setColorAnchor1(null)}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                          >
                            <Box sx={{ p: 2 }}>
                              <ChromePicker
                                color={pendingConfig.buttoncolor1}
                                onChange={(color) => handleChange('buttoncolor1', color.hex)}
                              />
                              <Button
                                fullWidth
                                variant="contained"
                                onClick={() => setColorAnchor1(null)}
                                sx={{
                                  mt: 2,
                                  backgroundColor: '#162d3a',
                                  color: '#ecf2f6',
                                  border: '1px solid #205a7b',
                                  '&:hover': {
                                    backgroundColor: '#1a3a4a',
                                    borderColor: '#4aa6da',
                                  },
                                }}
                              >
                                Seleccionar
                              </Button>
                            </Box>
                          </Popover>
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>
                          <Typography sx={{ 
                            color: '#96c3dd', 
                            mb: 1,
                            fontFamily: 'FSSinclair, sans-serif',
                            fontWeight: 600,
                            textAlign: 'center'
                          }}>Color Inferior</Typography>
                          <Button
                            onClick={(e) => setColorAnchor2(e.currentTarget)}
                            sx={{
                              minWidth: 'auto',
                              p: 0,
                              border: '2px solid #205a7b',
                              borderRadius: 1,
                              overflow: 'hidden',
                              display: 'inline-flex',
                              '&:hover': {
                                border: '2px solid #4aa6da',
                              }
                            }}
                          >
                            <Box
                              sx={{
                                backgroundColor: pendingConfig.buttoncolor2,
                                width: '36px',
                                height: '36px',
                              }}
                            />
                          </Button>
                          <Popover
                            open={Boolean(colorAnchor2)}
                            anchorEl={colorAnchor2}
                            onClose={() => setColorAnchor2(null)}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                          >
                            <Box sx={{ p: 2 }}>
                              <ChromePicker
                                color={pendingConfig.buttoncolor2}
                                onChange={(color) => handleChange('buttoncolor2', color.hex)}
                              />
                              <Button
                                fullWidth
                                variant="contained"
                                onClick={() => setColorAnchor2(null)}
                                sx={{
                                  mt: 2,
                                  backgroundColor: '#162d3a',
                                  color: '#ecf2f6',
                                  border: '1px solid #205a7b',
                                  '&:hover': {
                                    backgroundColor: '#1a3a4a',
                                    borderColor: '#4aa6da',
                                  },
                                }}
                              >
                                Seleccionar
                              </Button>
                            </Box>
                          </Popover>
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} sx={{ mt: 0.5 }}>
                    <Grid item xs={12} md={4}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={pendingConfig.STYLE_animation}
                            onChange={(e) => handleChange('STYLE_animation', e.target.checked)}
                            sx={{
                              '& .MuiSwitch-switchBase.Mui-checked': {
                                color: '#4aa6da',
                                '&:hover': { backgroundColor: 'rgba(74, 166, 218, 0.08)' },
                              },
                              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                backgroundColor: '#205a7b',
                              },
                            }}
                          />
                        }
                        label={<Typography sx={{ color: '#96c3dd', fontFamily: 'FSSinclair, sans-serif' }}>Animación de palpitar</Typography>}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={pendingConfig.STYLE_openanimation}
                            onChange={(e) => handleChange('STYLE_openanimation', e.target.checked)}
                            sx={{
                              '& .MuiSwitch-switchBase.Mui-checked': {
                                color: '#4aa6da',
                                '&:hover': { backgroundColor: 'rgba(74, 166, 218, 0.08)' },
                              },
                              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                backgroundColor: '#205a7b',
                              },
                            }}
                          />
                        }
                        label={<Typography sx={{ color: '#96c3dd', fontFamily: 'FSSinclair, sans-serif' }}>Animación de persiana</Typography>}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={pendingConfig.STYLE_firstrunopen}
                            onChange={(e) => handleChange('STYLE_firstrunopen', e.target.checked)}
                            sx={{
                              '& .MuiSwitch-switchBase.Mui-checked': {
                                color: '#4aa6da',
                                '&:hover': { backgroundColor: 'rgba(74, 166, 218, 0.08)' },
                              },
                              '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                backgroundColor: '#205a7b',
                              },
                            }}
                          />
                        }
                        label={<Typography sx={{ color: '#96c3dd', fontFamily: 'FSSinclair, sans-serif' }}>Abrir en primera visita</Typography>}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Paper>
          </Grid>

          {/* Columna derecha - Chatbot */}
          <Grid item xs={12} md={4}>
            <Paper
              elevation={3}
              sx={{
                backgroundColor: '#0b151b',
                border: '1px solid #205a7b',
                borderRadius: 2,
                p: 2,
                height: { xs: '60vh', md: '80vh' },
                overflow: 'hidden',
                mb: { xs: 2, md: 0 }
              }}
            >
              <Typography variant="h6" sx={{ color: '#ffffff', mb: 2, fontFamily: 'FSSinclair, sans-serif' }}>
                Vista Previa del asistente
              </Typography>
              <Box sx={{ 
                height: 'calc(100% - 50px)',
                backgroundColor: '#0b151b',
                borderRadius: 1,
                overflow: 'hidden'
              }}>
                <iframe
                  ref={iframeRef}
                  style={{
                    width: '100%',
                    height: '100%',
                    border: 'none',
                    backgroundColor: '#0b151b'
                  }}
                  title="Asistente Preview"
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>

        {/* Botón de guardar fijo */}
        {hasChanges && (
          <Box
            sx={{
              position: 'fixed',
              bottom: 20,
              right: 20,
              zIndex: 1000
            }}
          >
            <Button
              variant="contained"
              startIcon={<SaveIcon sx={{ color: '#4aa6da' }}/>}
              onClick={handleSave}
              sx={{
                backgroundColor: '#162d3a',
                color: '#ecf2f6',
                border: '1px solid #205a7b',
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: '#1a3a4a',
                  borderColor: '#4aa6da',
                  boxShadow: '0 0 10px rgba(74, 166, 218, 0.1)',
                },
                height: '40px',
                textTransform: 'none',
                fontSize: '0.9rem',
                fontWeight: 500,
                width: { xs: '100%', sm: 'auto' },
                flex: { xs: '1 1 auto', sm: '0 0 auto' },
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                '& .MuiButton-startIcon': {
                  marginRight: 1,
                  '& svg': {
                    fontSize: '1.2rem'
                  }
                }
              }}
            >
              Guardar Cambios
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default Settings;
