import React, { useState } from 'react';
import {
  Container,
  Box,
  Paper,
  TextField,
  Button,
  Typography,
  Alert,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate, Link } from 'react-router-dom';
import { useDateRange } from '../context/DateContext';
import { useUser } from '../context/UserContext';
import dayjs from 'dayjs';
import { HASH } from '../utils/helpers';
import { login } from '../services/api';

const Login = () => {
  const navigate = useNavigate();
  const { updateUser } = useUser();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { setStartDate, setEndDate } = useDateRange();

  // Verificar si los campos están rellenos
  const isFormFilled = email && password;

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    
    try {
      const hashedPassword = HASH(password);
      const userData = await login(email, hashedPassword);
      
      // Transformar los productos a la estructura de asistentes para mantener compatibilidad
      const assistants = userData.products ? userData.products.map(product => ({
        assistant: product.code,
        name: product.name
      })) : [];

      // Verificar si hay asistentes disponibles
      if (assistants.length === 0) {
        throw new Error('No hay asistentes disponibles para este usuario');
      }

      // Crear el objeto de usuario final con los asistentes transformados
      const transformedUserData = {
        ...userData,
        assistants
      };

      // Actualizar el contexto del usuario
      updateUser(transformedUserData);
      
      // Si el login es exitoso, configurar fechas por defecto y redirigir
      const today = dayjs();
      setStartDate(today.subtract(7, 'day'));
      setEndDate(today);
      
      navigate('/');
    } catch (error) {
      console.error('Error en el login:', error);
      setError(error.message || 'Error al iniciar sesión. Por favor, verifica tus credenciales.');
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#0b151b',
        backgroundImage: 'linear-gradient(135deg, #0b151b 0%, #1a2c3d 100%)',
      }}
    >
      <Container component="main" maxWidth="xs">
        <Paper
          elevation={24}
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'rgba(11, 21, 27, 0.8)',
            backdropFilter: 'blur(10px)',
            border: '1px solid rgba(150, 195, 221, 0.12)',
            borderRadius: 3,
          }}
        >
          <Box
            sx={{
              width: 56,
              height: 56,
              borderRadius: '50%',
              backgroundColor: 'primary.main',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 2,
            }}
          >
            <LockOutlinedIcon sx={{ fontSize: 32, color: '#0b151b' }} />
          </Box>

          <Typography
            component="h1"
            variant="h4"
            sx={{
              mb: 3,
              background: 'linear-gradient(45deg, #96c3dd 30%, #4aa6da 90%)',
              backgroundClip: 'text',
              textFillColor: 'transparent',
              fontWeight: 700,
            }}
          >
            Iniciar Sesión
          </Typography>

          <Box component="form" onSubmit={handleLogin} sx={{ mt: 1, width: '100%' }} autoComplete="on" method="POST">
            {error && (
              <Alert 
                severity="error" 
                sx={{ 
                  mb: 2,
                  backgroundColor: 'rgba(211, 47, 47, 0.1)',
                  color: '#ff5252',
                }}
              >
                {error}
              </Alert>
            )}

            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Correo Electrónico"
              name="username"
              autoComplete="username email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'rgba(150, 195, 221, 0.2)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(150, 195, 221, 0.3)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#96c3dd',
                  },
                },
                input: { color: '#96c3dd' },
                label: { color: '#96c3dd' }
              }}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{ input: { color: '#96c3dd' } }}
            />
            
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
              <Link 
                to="/recuperar-password" 
                style={{ 
                  color: '#96c3dd', 
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
              >
                ¿Olvidó su contraseña?
              </Link>
            </Box>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                py: 1.5,
                fontSize: '1.1rem',
                fontWeight: 600,
                textTransform: 'none',
                background: isFormFilled 
                  ? 'linear-gradient(45deg, #96c3dd 30%, #4aa6da 90%)'
                  : 'linear-gradient(45deg, rgba(150, 195, 221, 0.3) 30%, rgba(74, 166, 218, 0.3) 90%)',
                boxShadow: isFormFilled 
                  ? '0 3px 5px 2px rgba(74, 166, 218, .3)'
                  : 'none',
                transition: 'all 0.3s ease',
                '&:hover': {
                  background: isFormFilled 
                    ? 'linear-gradient(45deg, #4aa6da 30%, #96c3dd 90%)'
                    : 'linear-gradient(45deg, rgba(150, 195, 221, 0.4) 30%, rgba(74, 166, 218, 0.4) 90%)',
                  boxShadow: isFormFilled 
                    ? '0 4px 6px 2px rgba(74, 166, 218, .4)'
                    : '0 2px 4px rgba(74, 166, 218, .2)',
                },
                transform: isFormFilled ? 'scale(1.02)' : 'none',
              }}
            >
              Iniciar Sesión
            </Button>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default Login;
