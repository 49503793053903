import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDateRange } from '../context/DateContext';
import { useSelectedChatbot } from '../context/SelectedChatbotContext';
import {
  Box,
  Container,
  Typography,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Grid,
  Tooltip,
  Checkbox,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DateRangePicker from '../components/DateRangePicker';
import { fetchData, fetchChatConversation } from '../services/api';
import dayjs from 'dayjs';

// Estilos personalizados
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: '#ecf2f6',
  borderBottom: '1px solid rgba(32, 90, 123, 0.5)',
  padding: '16px',
  '&.header': {
    backgroundColor: '#0b151b',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    height: '57px',
    '&.numeric': {
      width: '55px',
      minWidth: '55px',
      maxWidth: '55px',
      padding: '4px 8px',
      textAlign: 'center',
      '& .MuiTableSortLabel-root': {
        display: 'inline-flex',
        '& .MuiTableSortLabel-icon': {
          marginLeft: '0px',
          position: 'static'
        }
      }
    },
    '&.fecha': {
      width: '120px',
      minWidth: '120px',
      maxWidth: '120px',
      paddingRight: '24px',
    },
    '&.datos-personales': {
      width: '200px',
      minWidth: '200px',
      maxWidth: '200px',
    }
  },
  '&.numeric': {
    width: '55px',
    minWidth: '55px',
    maxWidth: '55px',
    padding: '4px 8px',
    textAlign: 'center',
  },
  '&.fecha': {
    paddingRight: '24px',
  },
  '&.consulta': {
    padding: '12px 16px',
    '& .consulta-text': {
      color: '#ecf2f6',
      marginBottom: '4px',
      display: 'block',
      wordBreak: 'break-word',
      fontSize: '0.95em',
      minWidth: '150px', // Establece un ancho máximo
      maxWidth: '400px', // Establece un ancho máximo
      whiteSpace: 'wrap', // Evita que el texto se extienda a múltiples líneas
      overflow: 'hidden', // Oculta el contenido que excede el ancho máximo
      textOverflow: 'ellipsis', // Agrega puntos suspensivos (...) para el contenido recortado
    },
    '& .respuesta-text': {
      color: '#96c3dd',
      paddingLeft: '16px',
      display: 'block',
      fontSize: '0.85em',
      wordBreak: 'break-word',
      minWidth: '150px', // Establece un ancho máximo
      maxWidth: '400px', // Establece un ancho máximo
      whiteSpace: 'wrap', // Evita que el texto se extienda a múltiples líneas
      overflow: 'hidden', // Oculta el contenido que excede el ancho máximo
      textOverflow: 'ellipsis', // Agrega puntos suspensivos (...) para el contenido recortado
    }
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#0b151b',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#0f1e27',
  },
  '&:hover': {
    backgroundColor: '#162d3a',
    cursor: 'pointer',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: '#0b151b',
    color: '#96c3dd',
    border: '1px solid #205a7b',
  },
}));

const StyledMetricBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#162d3a',
  padding: '12px',
  borderRadius: '8px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& .label': {
    color: '#96c3dd',
    fontWeight: 'bold',
    fontSize: '0.8rem',
    marginBottom: '4px',
    height: '16px',
    textAlign: 'center'
  },
  '& .value': {
    color: '#ecf2f6',
    fontSize: '1.2rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
    textAlign: 'center',
    maxWidth: '100%',
    padding: '0 4px'
  },
  '& .personalDataValue': {
    color: '#ecf2f6',
    fontSize: '0.9rem',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    maxWidth: '100%',
    padding: '0 4px',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    lineHeight: 1.2,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical'
  },
  '& .date': {
    color: '#ecf2f6',
    fontSize: '0.75rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
    width: '100%'
  },
  '& .time': {
    color: '#ecf2f6',
    fontSize: '0.75rem',
    marginTop: '1px',
    textAlign: 'center',
    width: '100%'
  }
}));

const CategoryLabel = styled(Typography)(({ theme }) => ({
  color: '#96c3dd',
  fontWeight: 'bold',
  fontSize: '0.9rem',
  marginTop: '16px',
  marginBottom: '8px',
  marginLeft: '8px',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: '-8px',
    left: '-8px',
    right: 0,
    height: '1px',
    backgroundColor: '#205a7b'
  }
}));

const DialogSectionLabel = styled(Typography)(({ theme }) => ({
  color: '#96c3dd',
  fontWeight: 'bold',
  fontSize: '0.9rem',
  marginTop: '24px',
  marginBottom: '16px',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: '-8px',
    left: 0,
    right: 0,
    height: '1px',
    backgroundColor: '#205a7b',
    marginBottom: '8px'
  }
}));

const ChatContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#0f1e27',
  padding: '16px',
  borderRadius: '8px',
  marginTop: '24px',
  border: '1px solid #205a7b',
  minHeight: '100px',
  maxHeight: '500px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#0b151b',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#205a7b',
    borderRadius: '4px',
  },
}));

const MessageBubble = styled(Box)(({ isUser }) => ({
  maxWidth: '70%',
  marginBottom: '12px',
  alignSelf: isUser ? 'flex-end' : 'flex-start',
  backgroundColor: isUser ? '#1a5f7a' : '#162d3a',
  padding: '12px 16px',
  borderRadius: '12px',
  borderTopRightRadius: isUser ? '4px' : '12px',
  borderTopLeftRadius: isUser ? '12px' : '4px',
  color: '#ecf2f6',
  boxShadow: '0 1px 2px rgba(0,0,0,0.2)',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    top: 0,
    [isUser ? 'right' : 'left']: '-8px',
    borderTop: `8px solid ${isUser ? '#1a5f7a' : '#162d3a'}`,
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
  },
}));

const formatChatText = (text) => {
  if (!text) return [];

  const messages = [];
  let currentMessage = null;

  text.split('\n').forEach(line => {
    if (line.startsWith('Usuario : ')) {
      if (currentMessage) messages.push(currentMessage);
      currentMessage = {
        isUser: true,
        speaker: 'Usuario',
        message: line.substring('Usuario : '.length).trim()
      };
    } else if (line.startsWith('4LogicONE : ') || line.startsWith('Asistente : ')) {
      if (currentMessage) messages.push(currentMessage);
      const speaker = line.startsWith('4LogicONE : ') ? '4LogicONE' : 'Asistente';
      currentMessage = {
        isUser: false,
        speaker: speaker,
        message: line.substring(line.indexOf(' : ') + 3).trim()
      };
    } else if (currentMessage && line.trim()) {
      // Agregar líneas adicionales al mensaje actual
      currentMessage.message += '\n' + line.trim();
    }
  });

  // Agregar el último mensaje si existe
  if (currentMessage) messages.push(currentMessage);

  return messages;
};

const formatTime = (fecha, hora) => {
  return dayjs(fecha + hora, 'YYYYMMDDHHmmss').format('HH:mm:ss');
};

const formatDate = (fecha) => {
  return dayjs(fecha, 'YYYYMMDD').format('DD/MM/YYYY');
};

const formatDateTimeForBox = (fecha, hora) => {
  return {
    date: dayjs(fecha, 'YYYYMMDD').format('DD/MM/YYYY'),
    time: dayjs(hora, 'HHmmss').format('HH:mm:ss')
  };
};

const hasPersonalData = (chatDetail) => {
  return chatDetail.nombre || chatDetail.telefono || chatDetail.email ||
    chatDetail.direccion || chatDetail.referencia || chatDetail.referencias;
};

const getPersonalDataBoxes = (chatDetail) => {
  const boxes = [];
  if (chatDetail.nombre) boxes.push({ label: 'Nombre', value: chatDetail.nombre });
  if (chatDetail.telefono) boxes.push({ label: 'Teléfono', value: chatDetail.telefono });
  if (chatDetail.email) boxes.push({ label: 'Email', value: chatDetail.email });
  if (chatDetail.direccion) boxes.push({ label: 'Dirección', value: chatDetail.direccion });
  if (chatDetail.referencia) boxes.push({ label: 'Referencia', value: chatDetail.referencia });
  if (chatDetail.referencias) boxes.push({ label: 'Referencias', value: chatDetail.referencias });
  return boxes;
};

const Conversations = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [chatDetail, setChatDetail] = useState(null);
  const { startDate, endDate, setStartDate, setEndDate } = useDateRange();
  const { selectedChatbot } = useSelectedChatbot();
  const [orderBy, setOrderBy] = useState('fecha');
  const [order, setOrder] = useState('desc');
  const [showOnlyWithPersonalData, setShowOnlyWithPersonalData] = useState(false);

  const scrollRef = useRef(null);
  const isDragging = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  const handleMouseDown = (e) => {
    isDragging.current = false; // Reseteamos el estado de arrastre
    startX.current = e.pageX - scrollRef.current.offsetLeft;
    scrollLeft.current = scrollRef.current.scrollLeft;
  };

  const handleMouseMove = (e) => {
    if (e.buttons !== 1) return; // Asegúrate de que el botón del mouse esté presionado
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX.current) * 1; // Sensibilidad
    scrollRef.current.scrollLeft = scrollLeft.current - walk;
    isDragging.current = true; // Detecta que el usuario está arrastrando
  };

  const handleMouseUp = () => {
    setTimeout(() => {
      isDragging.current = false; // Resetea el estado después de soltar
    }, 0);
  };

  const handleClick = (e) => {
    if (isDragging.current) {
      e.preventDefault(); // Cancela el clic si fue un arrastre
      isDragging.current = false;
    }
  };

  const handleTouchStart = (e) => {
    isDragging.current = true;
    startX.current = e.touches[0].pageX - scrollRef.current.offsetLeft;
    scrollLeft.current = scrollRef.current.scrollLeft;
  };

  const handleTouchMove = (e) => {
    if (!isDragging.current) return;
    const x = e.touches[0].pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX.current) * 1;
    scrollRef.current.scrollLeft = scrollLeft.current - walk;
  };

  const handleTouchEnd = () => {
    isDragging.current = false;
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const filteredAndSortedChats = useMemo(() => {
    let filtered = [...chats];

    if (showOnlyWithPersonalData) {
      filtered = filtered.filter(hasPersonalData);
    }

    return filtered.sort((a, b) => {
      if (orderBy === 'fecha') {
        const dateA = dayjs(a.fecha + ' ' + a.hora, 'YYYYMMDD HHmmss');
        const dateB = dayjs(b.fecha + ' ' + b.hora, 'YYYYMMDD HHmmss');
        return order === 'asc' ? dateA.diff(dateB) : dateB.diff(dateA);
      }

      const valueA = a[orderBy];
      const valueB = b[orderBy];

      if (typeof valueA === 'string') {
        return order === 'asc'
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }

      return order === 'asc' ? valueA - valueB : valueB - valueA;
    });
  }, [chats, order, orderBy, showOnlyWithPersonalData]);

  const getColorForValue = (value) => {
    if (value <= 3) return '#ff5252';  // Rojo para valores bajos
    if (value <= 6) return '#ffd740';  // Amarillo para valores medios
    return '#69f0ae';  // Verde para valores altos
  };

  const getColorForValueUrg = (value) => {
    if (value <= 3) return '#69f0ae';  // Verde para urgencia baja
    if (value <= 6) return '#ffd740';  // Amarillo para urgencia media
    return '#ff5252';  // Rojo para urgencia alta
  };

  useEffect(() => {
    const fetchChats = async () => {
      if (!selectedChatbot) {
        setError('No hay asistente seleccionado');
        return;
      }

      setLoading(true);
      setError(null);
      try {
        const response = await fetchData(
          1,
          selectedChatbot,
          startDate.format('YYYYMMDD'),
          endDate.format('YYYYMMDD')
        );
        setChats(response || []);
        setError(null);
      } catch (err) {
        console.error('Error al cargar las conversaciones:', err);
        setError('Error al cargar las conversaciones. Por favor, inténtelo de nuevo más tarde.');
        setChats([]);
      } finally {
        setLoading(false);
      }
    };

    fetchChats();
  }, [startDate, endDate, selectedChatbot]);

  useEffect(() => {
    const handleChatbotChange = () => {
      setSelectedChat(null);
      setChatDetail(null);
      setOpenDialog(false);
      setChats([]);
      const fetchChats = async () => {
        setLoading(true);
        setError(null);
        try {
          if (!selectedChatbot) {
            setError('No hay asistente seleccionado');
            return;
          }

          const response = await fetchData(
            1,
            selectedChatbot,
            startDate.format('YYYYMMDD'),
            endDate.format('YYYYMMDD')
          );
          setChats(response || []);
          setError(null);
        } catch (err) {
          console.error('Error al cargar las conversaciones:', err);
          setError('Error al cargar las conversaciones. Por favor, inténtelo de nuevo más tarde.');
          setChats([]);
        } finally {
          setLoading(false);
        }
      };
      fetchChats();
    };

    window.addEventListener('chatbotChanged', handleChatbotChange);
    return () => {
      window.removeEventListener('chatbotChanged', handleChatbotChange);
    };
  }, [startDate, endDate, selectedChatbot]);

  const handleChatClick = async (chat) => {
    if (!selectedChatbot) {
      setError('No hay asistente seleccionado');
      return;
    }

    try {
      setSelectedChat(chat);
      setOpenDialog(true);
      const detail = await fetchChatConversation(
        1,
        selectedChatbot,
        chat.fecha,
        chat.fecha,
        chat.chatid
      );
      setChatDetail(detail?.[0] || null);
    } catch (err) {
      console.error('Error al cargar el detalle de la conversación:', err);
      setError('Error al cargar el detalle de la conversación');
    }
  };

  const formatDateTime = (fecha, hora) => {
    const formattedDate = dayjs(fecha + hora, 'YYYYMMDDHHmmss').format('DD/MM/YYYY HH:mm:ss');
    return formattedDate;
  };

  const handleExportCSV = () => {
    if (!selectedChatbot) {
      setError('No hay asistente seleccionado');
      return;
    }

    // Crear el contenido del CSV
    const headers = [
      'Asistente',
      'Fecha',
      'Hora',
      'Usuario',
      'ChatID',
      'Version',
      'Nombre',
      'Email',
      'Telefono',
      'Direccion',
      'Otros',
      'Referencias',
      'Consulta',
      'Respuesta',
      'Valoracion',
      'Urgencia',
      'Resuelto',
      'Relevancia'
    ].join(';');

    const csvContent = filteredAndSortedChats.map(chat => {
      // Asegurarse de que los campos con punto y coma o saltos de línea estén correctamente formateados
      const formatField = (field) => {
        if (field === null || field === undefined) return '';
        const stringField = String(field);
        if (stringField.includes(';') || stringField.includes('\n') || stringField.includes('"')) {
          return `"${stringField.replace(/"/g, '""')}"`;
        }
        return stringField;
      };

      return [
        formatField(selectedChatbot),
        formatField(formatDate(chat.fecha)),
        formatField(formatTime(chat.fecha, chat.hora)),
        formatField(chat.usuario),
        formatField(chat.chatid),
        formatField(chat.version),
        formatField(chat.nombre),
        formatField(chat.email),
        formatField(chat.telefono),
        formatField(chat.direccion),
        formatField(chat.otros),
        formatField(chat.referencias),
        formatField(chat.consulta),
        formatField(chat.respuesta),
        formatField(chat.valoracion),
        formatField(chat.urgencia),
        formatField(chat.resuelto),
        formatField(chat.relevancia)
      ].join(';');
    });

    // Combinar headers y contenido
    const csv = [headers, ...csvContent].join('\n');

    // Crear el blob y descargar
    const blob = new Blob(['\ufeff' + csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', `conversaciones_${dayjs().format('YYYYMMDD_HHmmss')}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        py: { xs: 0, sm: 0 },
        backgroundColor: '#0b151b',
        minHeight: '100vh',
        px: { xs: 0, sm: 2, md: 3 },
        overflow: 'hidden',
        maxWidth: '100vw',
      }}
    >
      <Box sx={{
        mx: { xs: 1, sm: 0 },
        mb: { xs: 3, sm: 4 },
      }}>
        <Paper
          elevation={3}
          sx={{
            p: { xs: 2, sm: 2 },
            borderRadius: 2,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            alignItems: { xs: 'stretch', md: 'center' },
            gap: { xs: 1, sm: 2 },
            backgroundColor: '#0b151b',
            border: '1px solid #205a7b',
            width: '100%',
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography variant="h4" component="h1" gutterBottom sx={{
              color: '#ecf2f6',
              fontSize: { xs: '1.25rem', sm: '2rem' },
              marginBottom: { xs: 0, sm: 0 },
              fontFamily: 'FSSinclair, sans-serif',
            }}>
              Conversaciones
            </Typography>
            <Typography variant="body1" sx={{
              color: '#96c3dd',
              fontSize: { xs: '0.813rem', sm: '1rem' },
              fontFamily: 'FSSinclair, sans-serif',
            }}>
              Historial de conversaciones del 4LogicOne
            </Typography>
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: 1, sm: 2 },
            alignItems: { xs: 'stretch', sm: 'center' },
            width: { xs: '100%', sm: 'auto' },
            mt: { xs: 1, sm: 0 },
            flexWrap: 'wrap'
          }}>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={setStartDate}
              onEndDateChange={setEndDate}
            />
            <Button
              variant="contained"
              startIcon={<FileDownloadIcon sx={{ color: '#4aa6da' }} />}
              onClick={handleExportCSV}
              sx={{
                backgroundColor: '#162d3a',
                color: '#ecf2f6',
                border: '1px solid #205a7b',
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: '#1a3a4a',
                  borderColor: '#4aa6da',
                  boxShadow: '0 0 10px rgba(74, 166, 218, 0.1)',
                },
                height: '40px',
                textTransform: 'none',
                fontSize: '0.9rem',
                fontWeight: 500,
                width: { xs: '100%', sm: 'auto' },
                flex: { xs: '1 1 auto', sm: '0 0 auto' },
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                '& .MuiButton-startIcon': {
                  marginRight: 1,
                  '& svg': {
                    fontSize: '1.2rem'
                  }
                }
              }}
            >
              Exportación CSV
            </Button>
          </Box>
        </Paper>

        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
            <CircularProgress />
          </Box>
        ) : error ? (
          <Paper
            sx={{
              p: 3,
              textAlign: 'center',
              backgroundColor: '#0b151b',
              border: '1px solid #205a7b',
            }}
          >
            <Typography color="error">{error}</Typography>
          </Paper>
        ) : !chats || chats.length === 0 ? (
          <Paper
            sx={{
              p: 3,
              textAlign: 'center',
              backgroundColor: '#0b151b',
              border: '1px solid #205a7b',
            }}
          >
            <Typography sx={{ color: '#96c3dd' }}>
              No hay registros de conversaciones para el período seleccionado.
            </Typography>
          </Paper>
        ) : (
          <Box sx={{
            mx: { xs: 1, sm: 0 },
            overflow: 'hidden',
            width: 'auto',
            mt: { xs: 3, sm: 4 },
          }}>
            <Paper
              elevation={3}
              sx={{
                backgroundColor: '#0b151b',
                border: '1px solid #205a7b',
                borderRadius: 2,
                width: '100%',
                overflow: 'hidden',
              }}
            >
              <Box
                ref={scrollRef}
                sx={{
                  overflowX: 'auto',
                  cursor: isDragging.current ? 'grabbing' : 'grab', // Cambia el cursor
                  width: '100%',
                  WebkitOverflowScrolling: 'touch',
                  msOverflowStyle: 'none',
                  scrollbarWidth: 'thin',
                  '&::-webkit-scrollbar': {
                    display: 'none'
                  },
                  '-webkit-overflow-scrolling': 'touch',
                }}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
              >
                <Table sx={{
                  minWidth: { xs: 'auto', sm: 650 },
                  backgroundColor: '#0b151b',
                  '& .MuiTableCell-root': {
                    borderColor: '#205a7b',
                    color: '#96c3dd',
                    whiteSpace: 'nowrap',
                    p: { xs: 1, sm: 2 },
                    fontSize: { xs: '0.813rem', sm: '0.875rem' }
                  }
                }}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell className="header fecha">
                        <TableSortLabel
                          active={orderBy === 'fecha'}
                          direction={order === 'asc' ? 'asc' : 'desc'}
                          onClick={() => handleRequestSort('fecha')}
                          sx={{
                            '& .MuiTableSortLabel-icon': {
                              color: '#4aa6da !important',
                            },
                          }}
                        >
                          Fecha
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell className="header">
                        Consulta
                      </StyledTableCell>
                      <StyledTableCell className="header datos-personales">
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          Datos personales
                          <Tooltip title="Mostrar solo conversaciones con datos personales">
                            <Checkbox
                              size="small"
                              checked={showOnlyWithPersonalData}
                              onChange={(e) => setShowOnlyWithPersonalData(e.target.checked)}
                              sx={{
                                color: '#4aa6da',
                                '&.Mui-checked': {
                                  color: '#4aa6da',
                                },
                                padding: 0,
                                marginLeft: '4px'
                              }}
                            />
                          </Tooltip>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell className="header numeric">
                        <TableSortLabel
                          active={orderBy === 'nummsj'}
                          direction={order === 'asc' ? 'asc' : 'desc'}
                          onClick={() => handleRequestSort('nummsj')}
                          sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            '& .MuiTableSortLabel-icon': {
                              marginLeft: '4px',
                              position: 'static',
                              opacity: 0,
                              transition: 'opacity 0.2s'
                            },
                            '&:hover .MuiTableSortLabel-icon': {
                              opacity: 1,
                            },
                            '&.Mui-active': {
                              color: '#ecf2f6',
                              '& .MuiTableSortLabel-icon': {
                                opacity: 1,
                                color: '#4aa6da'
                              }
                            }
                          }}
                        >
                          <Tooltip title="Número de mensajes en la conversación">
                            <span>Msjs</span>
                          </Tooltip>
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell className="header">
                        <TableSortLabel
                          active={orderBy === 'usuario'}
                          direction={order === 'asc' ? 'asc' : 'desc'}
                          onClick={() => handleRequestSort('usuario')}
                          sx={{
                            '& .MuiTableSortLabel-icon': {
                              color: '#4aa6da !important',
                            },
                          }}
                        >
                          Usuario
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell className="header numeric">
                        <TableSortLabel
                          active={orderBy === 'relevancia'}
                          direction={order === 'asc' ? 'asc' : 'desc'}
                          onClick={() => handleRequestSort('relevancia')}
                          sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            '& .MuiTableSortLabel-icon': {
                              marginLeft: '4px',
                              position: 'static',
                              opacity: 0,
                              transition: 'opacity 0.2s'
                            },
                            '&:hover .MuiTableSortLabel-icon': {
                              opacity: 1,
                            },
                            '&.Mui-active': {
                              color: '#ecf2f6',
                              '& .MuiTableSortLabel-icon': {
                                opacity: 1,
                                color: '#4aa6da'
                              }
                            }
                          }}
                        >
                          <Tooltip title="Relevancia: Es un valor numérico subjetivo del 0 al 10 indicando si la conversación es relevante para la empresa">
                            <span>Rel</span>
                          </Tooltip>
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell className="header numeric">
                        <TableSortLabel
                          active={orderBy === 'urgencia'}
                          direction={order === 'asc' ? 'asc' : 'desc'}
                          onClick={() => handleRequestSort('urgencia')}
                          sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            '& .MuiTableSortLabel-icon': {
                              marginLeft: '4px',
                              position: 'static',
                              opacity: 0,
                              transition: 'opacity 0.2s'
                            },
                            '&:hover .MuiTableSortLabel-icon': {
                              opacity: 1,
                            },
                            '&.Mui-active': {
                              color: '#ecf2f6',
                              '& .MuiTableSortLabel-icon': {
                                opacity: 1,
                                color: '#4aa6da'
                              }
                            }
                          }}
                        >
                          <Tooltip title="Urgencia: Es un valor numérico subjetivo del 0 al 10 indicando la urgencia de la conversación">
                            <span>Urg</span>
                          </Tooltip>
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell className="header numeric">
                        <TableSortLabel
                          active={orderBy === 'valoracion'}
                          direction={order === 'asc' ? 'asc' : 'desc'}
                          onClick={() => handleRequestSort('valoracion')}
                          sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            '& .MuiTableSortLabel-icon': {
                              marginLeft: '4px',
                              position: 'static',
                              opacity: 0,
                              transition: 'opacity 0.2s'
                            },
                            '&:hover .MuiTableSortLabel-icon': {
                              opacity: 1,
                            },
                            '&.Mui-active': {
                              color: '#ecf2f6',
                              '& .MuiTableSortLabel-icon': {
                                opacity: 1,
                                color: '#4aa6da'
                              }
                            }
                          }}
                        >
                          <Tooltip title="Valoración: Es un valor numérico subjetivo del 0 al 10 indicando si la conversación es valiosa comercialmente">
                            <span>Val</span>
                          </Tooltip>
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell className="header numeric">
                        <TableSortLabel
                          active={orderBy === 'resuelto'}
                          direction={order === 'asc' ? 'asc' : 'desc'}
                          onClick={() => handleRequestSort('resuelto')}
                          sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            '& .MuiTableSortLabel-icon': {
                              marginLeft: '0px',
                              position: 'static',
                              opacity: 0,
                              transition: 'opacity 0.2s'
                            },
                            '&:hover .MuiTableSortLabel-icon': {
                              opacity: 1,
                            },
                            '&.Mui-active': {
                              color: '#ecf2f6',
                              '& .MuiTableSortLabel-icon': {
                                opacity: 1,
                                color: '#4aa6da'
                              }
                            }
                          }}
                        >
                          <Tooltip title="Resolución: Es un valor numérico subjetivo del 0 al 10 indicando si se ha resuelto la consulta">
                            <span>Res</span>
                          </Tooltip>
                        </TableSortLabel>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(filteredAndSortedChats || []).map((chat) => (
                      <StyledTableRow
                        key={chat.chatid}
                        onClick={() => handleChatClick(chat)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <StyledTableCell>
                          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                            <Typography sx={{ color: '#ecf2f6', fontSize: '0.875rem' }}>
                              {formatDate(chat.fecha)}
                            </Typography>
                            <Typography sx={{ color: '#96c3dd', fontSize: '0.813rem', mt: 0.5 }}>
                              {formatTime(chat.fecha, chat.hora)}
                            </Typography>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell className="consulta">
                          <span className="consulta-text">{chat.consulta}</span>
                          <span className="respuesta-text">{chat.respuesta}</span>
                        </StyledTableCell>
                        <StyledTableCell>
                          {[
                            chat.nombre,
                            chat.telefono,
                            chat.email,
                            chat.direccion,
                            chat.referencias
                          ].filter(Boolean).join(', ') || ''}
                        </StyledTableCell>
                        <StyledTableCell className="numeric" sx={{ fontWeight: 600, fontSize: '0.9rem' }}>
                          {chat.nummsj}
                        </StyledTableCell>
                        <StyledTableCell sx={{ color: '#ecf2f6' }}>
                          {chat.usuario}
                        </StyledTableCell>
                        <StyledTableCell
                          className="numeric"
                          sx={{
                            color: `${getColorForValue(chat.relevancia)} !important`,
                            fontWeight: 600,
                            fontSize: '0.9rem',
                          }}>
                          {chat.relevancia}
                        </StyledTableCell>
                        <StyledTableCell
                          className="numeric"
                          sx={{
                            color: `${getColorForValueUrg(chat.urgencia)} !important`,
                            fontWeight: 600,
                            fontSize: '0.9rem',
                          }}>
                          {chat.urgencia}
                        </StyledTableCell>
                        <StyledTableCell
                          className="numeric"
                          sx={{
                            color: `${getColorForValue(chat.valoracion)} !important`,
                            fontWeight: 600,
                            fontSize: '0.9rem',
                          }}>
                          {chat.valoracion}
                        </StyledTableCell>
                        <StyledTableCell
                          className="numeric"
                          sx={{
                            color: `${getColorForValue(chat.resuelto)} !important`,
                            fontWeight: 600,
                            fontSize: '0.9rem',
                          }}>
                          {chat.resuelto}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Paper>
          </Box>
        )}

        <StyledDialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle sx={{ m: 0, p: 2, borderBottom: '1px solid #205a7b' }}>
            <Typography variant="h6" component="div" sx={{ color: '#ecf2f6' }}>
              Detalle de la Conversación
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => setOpenDialog(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: '#96c3dd',
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ p: 3, backgroundColor: '#0b151b' }}>
            {chatDetail && (
              <>
                <DialogSectionLabel>
                  Información
                </DialogSectionLabel>
                <Grid container spacing={2} sx={{ mb: 3 }}>
                  <Grid item xs={12} sm={6} md={2.4}>
                    <StyledMetricBox sx={{ minHeight: '90px' }}>
                      <Typography className="label">Fecha</Typography>
                      <Typography className="date">
                        {formatDateTimeForBox(chatDetail.fecha, chatDetail.hora).date}
                      </Typography>
                      <Typography className="time">
                        {formatDateTimeForBox(chatDetail.fecha, chatDetail.hora).time}
                      </Typography>
                    </StyledMetricBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2.4}>
                    <StyledMetricBox sx={{ minHeight: '90px' }}>
                      <Typography className="label">Relevancia</Typography>
                      <Typography
                        className="value"
                        sx={{ color: getColorForValue(chatDetail.relevancia) }}
                      >
                        {chatDetail.relevancia}
                      </Typography>
                    </StyledMetricBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2.4}>
                    <StyledMetricBox sx={{ minHeight: '90px' }}>
                      <Typography className="label">Urgencia</Typography>
                      <Typography
                        className="value"
                        sx={{ color: getColorForValueUrg(chatDetail.urgencia) }}
                      >
                        {chatDetail.urgencia}
                      </Typography>
                    </StyledMetricBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2.4}>
                    <StyledMetricBox sx={{ minHeight: '90px' }}>
                      <Typography className="label">Valoración</Typography>
                      <Typography
                        className="value"
                        sx={{ color: getColorForValue(chatDetail.valoracion) }}
                      >
                        {chatDetail.valoracion}
                      </Typography>
                    </StyledMetricBox>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2.4}>
                    <StyledMetricBox sx={{ minHeight: '90px' }}>
                      <Typography className="label">Resolución</Typography>
                      <Typography
                        className="value"
                        sx={{ color: getColorForValue(chatDetail.resuelto) }}
                      >
                        {chatDetail.resuelto}
                      </Typography>
                    </StyledMetricBox>
                  </Grid>
                </Grid>

                {hasPersonalData(chatDetail) && (
                  <>
                    <DialogSectionLabel>
                      Datos Personales
                    </DialogSectionLabel>
                    <Grid container spacing={2} sx={{ mb: 3 }}>
                      {getPersonalDataBoxes(chatDetail).map((box, index) => (
                        <Grid
                          item
                          key={box.label}
                          xs={12}
                          sm={6}
                          md={2.4}
                        >
                          <StyledMetricBox sx={{ minHeight: '90px' }}>
                            <Typography className="label">{box.label}</Typography>
                            <Typography className="personalDataValue">
                              {box.value}
                            </Typography>
                          </StyledMetricBox>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
                <ChatContainer>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '50px',
                  }}>
                    {formatChatText(chatDetail.texto).map((msg, index) => (
                      <MessageBubble key={index} isUser={msg.isUser}>
                        <Typography variant="caption" sx={{
                          color: msg.isUser ? '#96c3dd' : '#4aa6da',
                          display: 'block',
                          marginBottom: '4px',
                          fontWeight: 'bold'
                        }}>
                          {msg.speaker}
                        </Typography>
                        <Typography>
                          {msg.message}
                        </Typography>
                      </MessageBubble>
                    ))}
                  </Box>
                </ChatContainer>
              </>
            )}
          </DialogContent>
        </StyledDialog>
      </Box>
    </Container>
  );
};

export default Conversations;
