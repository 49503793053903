import { createContext, useContext, useState, useEffect } from 'react';
import { useUser } from './UserContext';

const SelectedChatbotContext = createContext();

export const SelectedChatbotProvider = ({ children }) => {
  const [selectedChatbot, setSelectedChatbot] = useState(null);
  const [availableAssistants, setAvailableAssistants] = useState([]);
  const { user } = useUser();

  const clearChatbotData = () => {
    setSelectedChatbot(null);
    setAvailableAssistants([]);
  };

  // Efecto para manejar los cambios en el usuario y sus productos
  useEffect(() => {
    if (!user) {
      setAvailableAssistants([]);
      setSelectedChatbot(null);
      return;
    }

    let assistants = [];
    
    // Transformamos los productos en asistentes
    if (user.products && user.products.length > 0) {
      assistants = user.products.map(product => ({
        assistant: product.code,
        name: product.name
      }));
      setAvailableAssistants(assistants);

      // Si no hay chatbot seleccionado o el actual ya no existe, seleccionamos el primero
      if (!selectedChatbot || !assistants.find(a => a.assistant === selectedChatbot)) {
        setSelectedChatbot(assistants[0].assistant);
      }
    } else {
      setAvailableAssistants([]);
      setSelectedChatbot(null);
    }
  }, [user, user?.products]); // Dependemos específicamente de user.products también

  return (
    <SelectedChatbotContext.Provider value={{ 
      selectedChatbot, 
      setSelectedChatbot, 
      availableAssistants,
      clearChatbotData 
    }}>
      {children}
    </SelectedChatbotContext.Provider>
  );
};

export const useSelectedChatbot = () => {
  const context = useContext(SelectedChatbotContext);
  if (!context) {
    throw new Error('useSelectedChatbot debe usarse dentro de un SelectedChatbotProvider');
  }
  return context;
};
