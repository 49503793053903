import axios from 'axios';

// URL base para las peticiones a la API.
const SERVER_URL = 'https://overlord.chat';
//const SERVER_URL = 'http://192.168.1.45';

const BASE_URL = SERVER_URL + '/api/v1/back/db';

const getToken = () => {
  const user = JSON.parse(sessionStorage.getItem('user'));
  return user?.token;
};

const axiosInstance = axios.create({
  timeout: 30000, // 30 segundos
  headers: {
    'Content-Type': 'application/json',
  }
});

const retryRequest = async (fn, retries = 3, delay = 1000) => {
  try {
    return await fn();
  } catch (error) {
    if (retries === 0) throw error;
    await new Promise(resolve => setTimeout(resolve, delay));
    return retryRequest(fn, retries - 1, delay * 2);
  }
};

/**
 * Obtiene los datos generales para el dashboard.
 * @param {number} tipo - Tipo de consulta.
 * @param {string} chatbot - Nombre del chatbot.
 * @param {string} fecha1 - Fecha de inicio (formato YYYYMMDD).
 * @param {string} fecha2 - Fecha de fin (formato YYYYMMDD).
 * @returns {Promise<object>} - Respuesta del backend.
 */
export const fetchData = async (tipo, chatbot, fecha1, fecha2) => {
  try {
    const sessionId = sessionStorage.getItem('sessionId');
    if (!sessionId) {
      throw new Error('No hay sesión activa');
    }

    const response = await axiosInstance.get(BASE_URL, {
      params: {
        tipo,
        chatbot,
        fecha1,
        fecha2,
      },
      headers: {
        'Authorization': `Bearer ${sessionId}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error al obtener los datos:', error);
    throw error;
  }
};

/**
 * Obtiene los datos de una conversación específica.
 * @param {number} tipo - Tipo de consulta.
 * @param {string} chatbot - Nombre del chatbot.
 * @param {string} fecha1 - Fecha de inicio (formato YYYYMMDD).
 * @param {string} fecha2 - Fecha de fin (formato YYYYMMDD).
 * @param {string} chatid - ID del chat específico.
 * @returns {Promise<object>} - Respuesta del backend con la conversación.
 */
export const fetchChatConversation = async (tipo, chatbot, fecha1, fecha2, chatid) => {
  try {
    const sessionId = sessionStorage.getItem('sessionId');
    if (!sessionId) {
      throw new Error('No hay sesión activa');
    }

    const response = await axiosInstance.get(BASE_URL, {
      params: {
        tipo,
        chatbot,
        fecha1,
        fecha2,
        vertexto: 1,
        chatid,
      },
      headers: {
        'Authorization': `Bearer ${sessionId}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error al obtener la conversación:', error);
    throw error;
  }
};

/**
 * Obtiene los datos de texto del chatbot.
 * @param {string} chatbotId - ID del chatbot.
 * @returns {Promise<object>} - Respuesta del backend con los datos de texto.
 */
export const fetchTextData = async (chatbotId) => {
  try {
    const sessionId = sessionStorage.getItem('sessionId');
    if (!sessionId) {
      throw new Error('No hay sesión activa');
    }

    const response = await axiosInstance.get(SERVER_URL + '/api/v1/back/text-data', {
      params: {
        id: chatbotId
      },
      headers: {
        'Authorization': `Bearer ${sessionId}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error en fetchTextData:', error);
    throw error;
  }
};

/**
 * Envía un mensaje al chatbot y obtiene su respuesta
 * @param {string} chatbotId - ID del chatbot
 * @param {string} message - Mensaje del usuario
 * @param {string} chatId - ID de la conversación (opcional)
 * @returns {Promise<object>} - Respuesta del chatbot
 */
export const sendChatMessage = async (chatbotId, message, chatId = '') => {
  try {
    const sessionId = sessionStorage.getItem('sessionId');
    if (!sessionId) {
      throw new Error('No hay sesión activa');
    }

    const API_URL = SERVER_URL + "/api/v1/chat";
    console.log('Enviando datos:', { clientCode: chatbotId, chatId, message });

    const response = await axios.post(API_URL, {
      clientCode: chatbotId,
      chatId: chatId || '', // Asegurarse de enviar vacío en lugar de null
      message: message,
    }, {
      timeout: 60000,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${sessionId}`
      },
      validateStatus: function (status) {
        return status >= 200 && status < 500;
      }
    });

    console.log('Respuesta del servidor:', response.data);
    if (!response.data) {
      throw new Error('Respuesta vacía del servidor');
    }

    return response.data;
  } catch (error) {
    console.error('Error al enviar el mensaje:', error);
    throw error;
  }
};

/**
 * Guarda las instrucciones del chatbot
 * @param {string} chatbotId - Código del cliente
 * @param {string} instructions - Texto de las instrucciones
 * @returns {Promise<void>} - Promesa vacía que se resuelve cuando se guarda correctamente
 */
export const saveInstructions = async (chatbotId, instructions) => {
  try {
    const sessionId = sessionStorage.getItem('sessionId');
    if (!sessionId) {
      throw new Error('No hay sesión activa');
    }

    const API_URL = SERVER_URL + "/api/v1/back/save-data";

      const response = await fetch(`${SERVER_URL}/api/v1/back/usr?ope=prd_savedata&prd=${chatbotId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
        'Authorization': `Bearer ${sessionId}`
      },
      body: instructions
    });

    if (!response.ok) {
      throw new Error('Error al guardar las instrucciones');
    }

    // No intentamos parsear la respuesta como JSON
    return;
  } catch (error) {
    console.error('Error al guardar las instrucciones:', error);
    throw error;
  }
};

/**
 * Obtiene los datos del chatbot
 * @param {string} chatbotId - ID del chatbot
 * @returns {Promise<object>} - Respuesta del backend con los datos del chatbot
 */
export const fetchChatbotData = async (chatbotId) => {
  try {
    const sessionId = sessionStorage.getItem('sessionId');
    if (!sessionId) {
      throw new Error('No hay sesión activa');
    }

    const response = await fetch(SERVER_URL + `/api/v1/back/chatbot-data?id=${chatbotId}`, {
      headers: {
        'Authorization': `Bearer ${sessionId}`
      }
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error al obtener datos del chatbot:', error);
    throw error;
  }
};

/**
 * Solicita la recuperación de contraseña para un correo electrónico
 * @param {string} email - Correo electrónico del usuario
 * @returns {Promise<object>} - Respuesta del backend con el keylink
 */
export const requestPasswordRecovery = async (email) => {
  try {
    const sessionId = sessionStorage.getItem('sessionId');
    if (!sessionId) {
      throw new Error('No hay sesión activa');
    }

    const response = await axiosInstance.post(`${SERVER_URL}/api/v1/back/usr?ope=user_passforget`, {
      email: email,
    }, {
      headers: {
        'Authorization': `Bearer ${sessionId}`
      }
    });
    console.log('Respuesta del servidor:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error al solicitar recuperación de contraseña:', error);
    throw error;
  }
};

/**
 * Resetea la contraseña de un usuario usando el linkcode
 * @param {string} linkcode - Código único para resetear la contraseña
 * @param {string} newPassword - Nueva contraseña hasheada del usuario
 * @returns {Promise<object>} - Respuesta del backend
 */
export const resetPassword = async (linkcode, newPassword) => {
  try {
    const sessionId = sessionStorage.getItem('sessionId');
    if (!sessionId) {
      throw new Error('No hay sesión activa');
    }

    const response = await axiosInstance.post(`${SERVER_URL}/api/v1/back/usr?ope=user_passnew`, {
      linkcode: linkcode,
      passnew: newPassword
    }, {
      headers: {
        'Authorization': `Bearer ${sessionId}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error al resetear la contraseña:', error);
    throw error;
  }
};

/**
 * Realiza el login del usuario.
 * @param {string} email - Correo electrónico del usuario.
 * @param {string} password - Contraseña sin hashear del usuario.
 * @returns {Promise<object>} - Datos del usuario y token de sesión.
 */
export const login = async (email, password) => {
  try {
    const response = await axiosInstance.post(`${SERVER_URL}/api/v1/back/usr?ope=user_login`, {
      email: email,
      passhash: password
    });
    if (response.data) {
      const { session, ...userData } = response.data;

      // Guardar el sessionId por separado
      sessionStorage.setItem('sessionId', session);

      // Guardar los datos del usuario en sessionStorage
      const userDataToStore = {
        ...userData,
        session
      };
      console.log(response.data);
      console.log(session);
      sessionStorage.setItem('user', JSON.stringify(userDataToStore));
      console.log('✅ Usuario logueado correctamente');

      return response.data;
    }

    return response.data;
  } catch (error) {
    console.error('❌ Error en login:', error);
    if (!error.response || !error.response.data) {
      throw new Error('Credenciales incorrectas. Por favor, verifica tu email y contraseña.');
    } else {
      throw new Error('Error al intentar iniciar sesión. Por favor, inténtalo de nuevo más tarde.');
    }
  }
};

/**
 * Cierra la sesión del usuario.
 * @returns {Promise<void>} - Promesa vacía que se resuelve cuando se cierra la sesión correctamente.
 */
export const logout = async () => {
  try {
    const sessionId = sessionStorage.getItem('sessionId');

    // Si hay sessionId, intentamos hacer logout en el backend
    if (sessionId) {
      try {
        await axiosInstance.post(`${SERVER_URL}/api/v1/back/usr?ope=user_logout`, null, {
          headers: {
            'Authorization': `Bearer ${sessionId}`
          }
        });
        console.log('✅ Usuario deslogueado correctamente');
      } catch (error) {
        console.error('Error al cerrar sesión en el backend:', error);
        // Continuamos con la limpieza local aunque falle el backend
      }
    }

    // Siempre limpiamos el sessionStorage
    sessionStorage.removeItem('sessionId');
    sessionStorage.removeItem('user');

    return { success: true };
  } catch (error) {
    console.error('Error en el proceso de logout:', error);
    // Incluso si hay error, intentamos limpiar el storage
    sessionStorage.removeItem('sessionId');
    sessionStorage.removeItem('user');
    throw error;
  }
};

/**
 * Edita el nombre del usuario.
 * @param {string} newName - Nuevo nombre del usuario
 * @returns {Promise<object>} - Datos actualizados del usuario
 */
export const editUserName = async (newName) => {
  try {
    const sessionId = sessionStorage.getItem('sessionId');
    if (!sessionId) {
      throw new Error('No hay sesión activa');
    }

    const response = await axiosInstance.post(`${SERVER_URL}/api/v1/back/usr?ope=user_edit`, {
      name: newName
    }, {
      headers: {
        'Authorization': `Bearer ${sessionId}`
      }
    });

    if (response.data) {
      // Actualizar los datos del usuario en sessionStorage
      const userData = JSON.parse(sessionStorage.getItem('user'));
      const updatedUserData = {
        ...userData,
        name: newName
      };
      sessionStorage.setItem('user', JSON.stringify(updatedUserData));
      console.log('✅ Nombre de usuario actualizado correctamente');
      return updatedUserData;
    }

    return response.data;
  } catch (error) {
    console.error('Error al actualizar el nombre:', error);
    throw error;
  }
};

/**
 * Cambia la contraseña del usuario.
 * @param {string} oldPassword - Contraseña actual
 * @param {string} newPassword - Nueva contraseña
 * @returns {Promise<object>} - Respuesta del servidor
 */
export const changePassword = async (oldPassword, newPassword) => {
  try {
    const sessionId = sessionStorage.getItem('sessionId');
    if (!sessionId) {
      throw new Error('No hay sesión activa');
    }

    const response = await axiosInstance.post(`${SERVER_URL}/api/v1/back/usr?ope=user_passchange`, {
      passold: oldPassword,
      passnew: newPassword
    }, {
      headers: {
        'Authorization': `Bearer ${sessionId}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error al cambiar la contraseña:', error);
    throw error;
  }
};

/**
 * Crea una nueva organización y actualiza la lista de organizaciones en sessionStorage
 * @param {string} name - Nombre de la organización
 * @param {string} parentorg - Código de la organización padre
 * @returns {Promise<object>} - Respuesta del servidor
 */
export const createOrganization = async (name, parentorg) => {
  try {
    const sessionId = sessionStorage.getItem('sessionId');

    if (!sessionId) {
      throw new Error('No hay sesión activa');
    }

    const response = await axiosInstance.post(`${SERVER_URL}/api/v1/back/usr?ope=org_new`, {
      name: name,
      parentorg: parentorg,
      active: true
    }, {
      headers: {
        'Authorization': `Bearer ${sessionId}`
      }
    });
    console.log('Respuesta del servidor:', response.data);

    // Si la creación fue exitosa, actualizamos todos los datos del usuario en sessionStorage
    if (response.data) {
      const userData = JSON.parse(sessionStorage.getItem('user'));
      const updatedUserData = {
        ...userData,
        email: response.data.email || userData.email,
        name: response.data.name || userData.name,
        organization: response.data.organization || userData.organization,
        organizations: response.data.organizations || userData.organizations,
        products: response.data.products || userData.products,
        rol: response.data.rol || userData.rol
      };
      sessionStorage.setItem('user', JSON.stringify(updatedUserData));
      console.log('✅ Organización creada y datos de usuario actualizados correctamente');
    }

    console.log(response.data);
    console.log('%c ✓ Organización creada correctamente', 'color: #4CAF50; font-weight: bold;');
    return response.data;
  } catch (error) {
    console.error('Error al crear la organización:', error);
    throw error;
  }
};

/**
 * Obtiene la lista de usuarios
 * @returns {Promise<object>} - Lista de usuarios
 */
export const getUsersList = async () => {
  try {
    const sessionId = sessionStorage.getItem('sessionId');

    if (!sessionId) {
      throw new Error('No hay sesión activa');
    }

    const response = await axiosInstance.post(`${SERVER_URL}/api/v1/back/usr?ope=users_list`, {}, {
      headers: {
        'Authorization': `Bearer ${sessionId}`
      }
    });

    console.log('Lista de usuarios:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error al obtener la lista de usuarios:', error);
    throw error;
  }
};

/**
 * Crea un nuevo usuario
 * @param {string} organization - Código de la organización
 * @param {string} email - Email del usuario
 * @param {string} name - Nombre del usuario
 * @param {string} rol - Rol del usuario
 * @param {string} passhash - Contraseña hasheada
 * @param {string} passvisible - Contraseña visible generada
 * @returns {Promise<object>} - Respuesta del servidor
 */
export const user_new = async (organization, email, name, rol, passhash, passvisible) => {
  try {
    const sessionId = sessionStorage.getItem('sessionId');
    if (!sessionId) {
      throw new Error('No hay sesión activa');
    }
    console.log('Organizacion:', organization);
    const response = await axiosInstance.post(`${SERVER_URL}/api/v1/back/usr?ope=user_new`, {
      organization,
      email,
      name,
      rol,
      passhash,
      passvisible
    }, {
      headers: {
        'Authorization': `Bearer ${sessionId}`
      }
    });

    // Si la respuesta incluye los datos del usuario y sus productos, los guardamos
    if (response.data && response.data.products) {
      console.log('Productos asignados al usuario:', response.data.products);
    }

    return response.data;
  } catch (error) {
    if (error.response) {
      // El servidor respondió con un código de error
      console.error('Error del backend:', {
        status: error.response.status,
        data: error.response.data,
        headers: error.response.headers
      });
    } else if (error.request) {
      // La petición se hizo pero no se recibió respuesta
      console.error('El correo electrónico ya existe');
      throw new Error('El correo electrónico ya existe');
    } else {
      // Error al configurar la petición
      console.error('Error al crear nuevo usuario:', error.message);
    }
    throw error;
  }
};

/**
 * Obtiene la configuración del chatbot
 * @param {string} chatbotId - ID del chatbot
 * @returns {Promise<object>} - Configuración del chatbot
 */
export const getChatbotConfig = async (chatbotId) => {
  try {
    const sessionId = sessionStorage.getItem('sessionId');
    if (!sessionId) {
      throw new Error('No hay sesión activa');
    }

    const response = await axiosInstance.get(`${SERVER_URL}/api/v1/back/chatbot-data`, {
      params: { id: chatbotId },
      headers: {
        'Authorization': `Bearer ${sessionId}`
      }
    });
    console.log('Respuesta del servidor:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error al obtener la configuración del chatbot:', error);
    throw error;
  }
};

/**
 * Guarda la configuración del chatbot
 * @param {string} chatbotId - ID del chatbot
 * @param {object} config - Nueva configuración del chatbot
 * @returns {Promise<void>} - Promesa vacía que se resuelve cuando se guarda correctamente
 */
export const saveChatbotConfig = async (chatbotId, config) => {
  try {
    const sessionId = sessionStorage.getItem('sessionId');
    if (!sessionId) {
      throw new Error('No hay sesión activa');
    }
    console.log('CodigoAsistente:', chatbotId);
    console.log('Configuración a guardar:', config);

    var jsonText = JSON.stringify(config,null,2);
    const response = await fetch(`${SERVER_URL}/api/v1/back/usr?ope=prd_saveconfig&prd=${chatbotId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain',
        'Authorization': `Bearer ${sessionId}`
      },
      body: jsonText
    });

    if (!response.ok) {
      throw new Error('Error al guardar la configuración');
    }

    // No intentamos parsear la respuesta como JSON
    return;
  } catch (error) {
    console.error('Error al guardar la configuración del chatbot:', error);
    throw error;
  }
};

/**
 * Login como otro usuario (solo para admins)
 * @param {string} userEmail - Email del usuario al que queremos acceder
 * @returns {Promise<object>} - Datos del usuario y token de sesión
 */
export const loginAsUser = async (userEmail) => {
  try {
    const sessionId = sessionStorage.getItem('sessionId');
    if (!sessionId) {
      throw new Error('No hay sesión activa');
    }

    const response = await axiosInstance.post(`${SERVER_URL}/api/v1/back/usr?ope=user_loginadmin`, {
      email: userEmail
    }, {
      headers: {
        'Authorization': `Bearer ${sessionId}`
      }
    });

    if (response.data) {
      return response.data;
    }

    throw new Error('Error al hacer login como usuario');
  } catch (error) {
    console.error('Error en loginAsUser:', error);
    throw error;
  }
};

/**
 * Obtiene el hash para generar los enlaces de las demos
 * @param {string} chatbotId - ID del chatbot
 * @returns {Promise<object>} - Objeto con el hash para las demos
 */
export const getDemoData = async (chatbotId) => {
  try {
    const sessionId = sessionStorage.getItem('sessionId');
    if (!sessionId) {
      throw new Error('No hay sesión activa');
    }

    const response = await axiosInstance.post(`${SERVER_URL}/api/v1/back/usr?ope=prd_demodata&prd=${chatbotId}`, {}, {
      headers: {
        'Authorization': `Bearer ${sessionId}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error al obtener los datos de las demos:', error);
    throw error;
  }
};