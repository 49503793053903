import React from 'react';
import { Container, Typography, Box, Paper, Grid } from '@mui/material';

const Analytics = () => {
  return (
    <Container maxWidth={false}>
      <Box sx={{ py: 0 }}>
        <Paper
          elevation={3}
          sx={{
            backgroundColor: '#0b151b',
            border: '1px solid #205a7b',
            borderRadius: 2,
            p: 2
          }}
        >
          <Typography variant="h4" sx={{ color: '#ffffff', mb: 0, fontFamily: 'FSSinclair, sans-serif', }}>
            Analíticas
          </Typography>
          <Typography variant="body1" sx={{ color: '#96c3dd', fontFamily: 'FSSinclair, sans-serif' }}>
            Disponible proximamente...
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
};

export default Analytics;
