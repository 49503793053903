import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  Modal,
  TextField,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  IconButton,
  Divider,
  Grid,
  Snackbar,
  Alert
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import LoginIcon from '@mui/icons-material/Login';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useUser } from '../context/UserContext';
import { getUsersList, user_new, loginAsUser } from '../services/api';
import { HASH } from '../utils/helpers';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#0b151b',
  boxShadow: 24,
  p: 4,
};

// Estilos personalizados para las celdas de la tabla
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: '#ecf2f6',
  borderBottom: '1px solid rgba(32, 90, 123, 0.5)',
  padding: '16px',
  '&.header': {
    backgroundColor: '#0b151b',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    height: '57px',
  }
}));

const Usuarios = () => {
  const { user } = useUser();
  const isAdmin = user?.rol === 'admin';
  const [openModal, setOpenModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({
    organization: '',
    name: '',
    email: '',
    rol: 'user'
  });
  const [errors, setErrors] = useState({
    email: ''
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // Función para formatear el rol
  const formatRol = (rol) => {
    switch (rol) {
      case 'admin':
        return 'Admin';
      case 'partner':
        return 'Partner';
      case 'user':
        return 'Usuario';
      default:
        return rol;
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await getUsersList();
        if (response?.users) {
          // Enriquecer los datos de usuarios con los nombres de las organizaciones
          const enrichedUsers = response.users.map(u => {
            const org = user?.organizations?.find(org => org.code === u.organization);
            return {
              ...u,
              organizationName: org ? org.name : u.organization
            };
          });
          setUsers(enrichedUsers);
        }
      } catch (error) {
        console.error('Error al obtener la lista de usuarios:', error);
      }
    };

    fetchUsers();
  }, [user?.organizations]);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setNewUser({ organization: '', name: '', email: '', rol: 'user' });
    setErrors({ email: '' });
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser(prev => ({
      ...prev,
      [name]: value
    }));

    // Validación de email en tiempo real
    if (name === 'email') {
      setErrors(prev => ({
        ...prev,
        email: validateEmail(value) ? '' : 'Email inválido'
      }));
    }
  };

  // Función para generar una contraseña aleatoria (2 letras + 4 números)
  const generatePassword = () => {
    const letters = 'abcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';

    let password = '';
    // Generar 2 letras aleatorias
    for (let i = 0; i < 2; i++) {
      password += letters.charAt(Math.floor(Math.random() * letters.length));
    }
    // Generar 4 números aleatorios
    for (let i = 0; i < 4; i++) {
      password += numbers.charAt(Math.floor(Math.random() * numbers.length));
    }
    return password;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validar antes de enviar
    if (!validateEmail(newUser.email)) {
      setErrors({
        ...errors,
        email: 'El formato del email no es válido'
      });
      return;
    }

    try {
      // Generar contraseña visible
      const passvisible = generatePassword();
      // Generar hash de la contraseña usando la función HASH
      const passhash = HASH(passvisible);

      // Llamar a la API para crear el usuario
      const response = await user_new(
        newUser.organization,
        newUser.email,
        newUser.name,
        newUser.rol,
        passhash,
        passvisible
      );

      if (response) {
        // Actualizar la lista de usuarios
        const updatedUsers = await getUsersList();
        if (updatedUsers?.users) {
          // Enriquecer los datos de usuarios con los nombres de las organizaciones
          const enrichedUsers = updatedUsers.users.map(u => {
            const org = user?.organizations?.find(org => org.code === u.organization);
            return {
              ...u,
              organizationName: org ? org.name : u.organization
            };
          });
          setUsers(enrichedUsers);
        }

        // Cerrar el modal y limpiar el formulario
        handleCloseModal();

        // Mostrar mensaje de éxito con Snackbar
        setSnackbar({
          open: true,
          message: `Usuario creado exitosamente.\nEnviado correo a ${newUser.email} con sus credenciales de acceso.`,
          severity: 'success'
        });
      }
    } catch (error) {
      console.error('Error al crear el usuario:', error);
      // Mostrar el error específico si el correo ya existe
      if (error.message === 'El correo electrónico ya existe') {
        setErrors({
          ...errors,
          email: 'Este correo electrónico ya está registrado'
        });
      } else {
        // Mostrar error genérico con Snackbar
        setSnackbar({
          open: true,
          message: 'Error al crear el usuario. Por favor, inténtalo de nuevo.',
          severity: 'error'
        });
      }
    }
  };

  // Función para manejar el login como usuario
  const handleLoginAsUser = async (userEmail) => {
    try {
      const loginData = await loginAsUser(userEmail);

      // Abrir nueva ventana
      const newWindow = window.open('/', '_blank');

      // Cuando la ventana esté lista, configurar la sesión
      newWindow.onload = () => {
        // Guardar los datos de la sesión
        newWindow.sessionStorage.setItem('sessionId', loginData.session);
        newWindow.sessionStorage.setItem('user', JSON.stringify({
          ...loginData,
          impersonated: true // Marcar que es una sesión impersonada
        }));

        // Recargar para inicializar con la nueva sesión
        newWindow.location.reload();

        // Manejar el cierre de la ventana
        newWindow.onbeforeunload = () => {
          // Limpiar la sesión al cerrar
          newWindow.sessionStorage.removeItem('sessionId');
          newWindow.sessionStorage.removeItem('user');
        };
      };
    } catch (error) {
      console.error('Error al hacer login como usuario:', error);
      // Mostrar el error en el snackbar
      setSnackbar({
        open: true,
        message: 'Error al acceder como usuario: ' + error.message,
        severity: 'error'
      });
    }
  };

  return (
    <Container maxWidth={false}>
      <Box sx={{ p: 0 }}>
        {/* Header */}
        <Paper
          elevation={3}
          sx={{
            backgroundColor: '#0b151b',
            border: '1px solid #205a7b',
            borderRadius: 2,
            p: 2
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
            <Box>
              <Typography variant="h4" sx={{ color: '#ffffff', mb: 1, fontFamily: 'FSSinclair, sans-serif' }}>
                Usuarios
              </Typography>
              <Typography variant="body1" sx={{ color: '#96c3dd', fontFamily: 'FSSinclair, sans-serif' }}>
                Gestiona tus clientes. Dales de alta, baja o modifica sus datos.
              </Typography>
              <Typography variant="body2" sx={{ color: '#4aa6da', fontFamily: 'FSSinclair, sans-serif', mt: 1 }}>
                Total usuarios: {users.length}
              </Typography>
            </Box>
            <Button
              variant="contained"
              startIcon={<AddIcon sx={{ color: '#4aa6da' }} />}
              onClick={handleOpenModal}
              sx={{
                backgroundColor: '#162d3a',
                color: '#ecf2f6',
                border: '1px solid #205a7b',
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: '#1a3a4a',
                  borderColor: '#4aa6da',
                  boxShadow: '0 0 10px rgba(74, 166, 218, 0.1)',
                },
                height: '40px',
                textTransform: 'none',
                fontSize: '0.9rem',
                fontWeight: 500,
                width: { xs: '100%', sm: 'auto' },
                flex: { xs: '1 1 auto', sm: '0 0 auto' },
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                '& .MuiButton-startIcon': {
                  marginRight: 1,
                  '& svg': {
                    fontSize: '1.2rem'
                  }
                }
              }}
            >
              Nuevo Usuario
            </Button>
          </Box>
        </Paper>

        {/* Table Box */}
        <Paper
          elevation={3}
          sx={{
            backgroundColor: '#0b151b',
            border: '1px solid #205a7b',
            borderRadius: 2,
            overflow: 'hidden',
            mt: 2
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell className="header">Email</StyledTableCell>
                  <StyledTableCell className="header">Nombre</StyledTableCell>
                  <StyledTableCell className="header">Organización</StyledTableCell>
                  <StyledTableCell className="header">Rol</StyledTableCell>
                  {/* Solo mostrar las columnas de acciones si el usuario es admin */}
                  {isAdmin && (
                    <>
                      <StyledTableCell className="header" sx={{ width: '100px', padding: '6px' }}>Acciones</StyledTableCell>
                      <StyledTableCell className="header" align="center" sx={{ width: '50px', padding: '6px' }}>Login</StyledTableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user, index) => (
                  <TableRow
                    key={user.email}
                    sx={{
                      backgroundColor: index % 2 === 0 ? '#0b151b' : '#10202b',
                      '&:hover': {
                        backgroundColor: '#162d3a',
                      },
                      cursor: 'pointer',
                    }}
                  >
                    <StyledTableCell>{user.email}</StyledTableCell>
                    <StyledTableCell>{user.name}</StyledTableCell>
                    <StyledTableCell>{user.organizationName}</StyledTableCell>
                    <StyledTableCell>{formatRol(user.rol)}</StyledTableCell>
                    {isAdmin && (
                      <>
                        <StyledTableCell sx={{ width: '100px', padding: '6px' }}>
                          <Stack direction="row" spacing={0.5} justifyContent="flex-start">
                            <IconButton
                              size="small"
                              onClick={() => { }}
                              sx={{
                                backgroundColor: '#162d3a',
                                color: '#4aa6da',
                                border: '1px solid #205a7b',
                                '&:hover': {
                                  backgroundColor: '#1a3a4a',
                                  borderColor: '#4aa6da',
                                },
                                width: '32px',
                                height: '32px'
                              }}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              size="small"
                              onClick={() => { }}
                              sx={{
                                backgroundColor: '#162d3a',
                                color: '#ff4444',
                                border: '1px solid #8b0000',
                                '&:hover': {
                                  backgroundColor: '#1a3a4a',
                                  borderColor: '#ff6666',
                                },
                                width: '32px',
                                height: '32px'
                              }}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Stack>
                        </StyledTableCell>
                        <StyledTableCell align="center" sx={{ width: '50px', padding: '16px' }}>
                          <IconButton
                            size="small"
                            onClick={() => handleLoginAsUser(user.email)}
                            sx={{
                              backgroundColor: '#162d3a',
                              color: '#4caf50',
                              border: '1px solid #2e7d32',
                              '&:hover': {
                                backgroundColor: '#1a3a4a',
                                borderColor: '#66bb6a',
                              },
                              width: '32px',
                              height: '32px'
                            }}
                          >
                            <LoginIcon fontSize="small" />
                          </IconButton>
                        </StyledTableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        {/* Modal de nuevo usuario */}
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-nuevo-usuario"
          aria-describedby="modal-crear-nuevo-usuario"
        >
          <Paper sx={modalStyle}>
            {/* Header con título centrado */}
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              mb: 2
            }}>
              <Typography
                variant="h6"
                sx={{
                  color: '#ffffff',
                  fontFamily: 'FSSinclair, sans-serif',
                  textAlign: 'center'
                }}
              >
                Nuevo Usuario
              </Typography>
            </Box>

            <Divider sx={{
              mb: 3,
              backgroundColor: '#205a7b',
              opacity: 0.5
            }} />

            <form onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <FormControl fullWidth>
                  <InputLabel sx={{ color: '#96c3dd' }}>Organización</InputLabel>
                  <Select
                    name="organization"
                    value={newUser.organization}
                    onChange={handleInputChange}
                    required
                    sx={{
                      color: '#ffffff',
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#205a7b'
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#4aa6da'
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#4aa6da'
                      }
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          bgcolor: '#0b151b',
                          '& .MuiMenuItem-root': {
                            color: '#ffffff'
                          },
                          '& .MuiMenuItem-root:hover': {
                            bgcolor: '#205a7b'
                          }
                        }
                      }
                    }}
                  >
                    {user?.organizations?.map((org) => (
                      <MenuItem
                        key={org.code}
                        value={org.code}
                      >
                        {org.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  label="Nombre"
                  name="name"
                  value={newUser.name}
                  onChange={handleInputChange}
                  required
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#205a7b',
                      },
                      '&:hover fieldset': {
                        borderColor: '#4aa6da',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: '#96c3dd',
                    },
                    '& .MuiOutlinedInput-input': {
                      color: '#ffffff',
                    },
                  }}
                />
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={newUser.email}
                  onChange={handleInputChange}
                  error={!!errors.email}
                  helperText={errors.email}
                  required
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#205a7b',
                      },
                      '&:hover fieldset': {
                        borderColor: '#4aa6da',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: '#96c3dd',
                    },
                    '& .MuiOutlinedInput-input': {
                      color: '#ffffff',
                    },
                    '& .MuiFormHelperText-root': {
                      color: '#ff4444',
                    },
                  }}
                />
                {user?.rol === 'admin' && (
                  <FormControl fullWidth>
                    <InputLabel sx={{ color: '#96c3dd' }}>Rol</InputLabel>
                    <Select
                      name="rol"
                      value={newUser.rol}
                      onChange={handleInputChange}
                      required
                      sx={{
                        color: '#ffffff',
                        '.MuiOutlinedInput-notchedOutline': {
                          borderColor: '#205a7b'
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#4aa6da'
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#4aa6da'
                        }
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            bgcolor: '#0b151b',
                            '& .MuiMenuItem-root': {
                              color: '#ffffff'
                            },
                            '& .MuiMenuItem-root:hover': {
                              bgcolor: '#205a7b'
                            }
                          }
                        }
                      }}
                    >
                      <MenuItem value="admin">Admin</MenuItem>
                      <MenuItem value="partner">Partner</MenuItem>
                      <MenuItem value="user">Usuario</MenuItem>
                    </Select>
                  </FormControl>
                )}
                <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
                  <Button
                    onClick={handleCloseModal}
                    variant="contained"
                    sx={{
                      backgroundColor: '#1e1e1e',
                      color: '#96c3dd',
                      border: '1px solid #333333',
                      '&:hover': {
                        backgroundColor: '#2a2a2a',
                        borderColor: '#444444',
                      },
                      flex: 1,
                      textTransform: 'none',
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    startIcon={<CheckIcon sx={{ color: '#4aa6da' }} />}
                    sx={{
                      backgroundColor: '#162d3a',
                      color: '#ecf2f6',
                      border: '1px solid #205a7b',
                      boxShadow: 'none',
                      '&:hover': {
                        backgroundColor: '#1a3a4a',
                        borderColor: '#4aa6da',
                        boxShadow: '0 0 10px rgba(74, 166, 218, 0.1)',
                      },
                      flex: 1,
                      textTransform: 'none',
                      fontSize: '0.9rem',
                      fontWeight: 500,
                      '& .MuiButton-startIcon': {
                        marginRight: 1,
                        '& svg': {
                          fontSize: '1.2rem'
                        }
                      }
                    }}
                  >
                    Confirmar
                  </Button>
                </Stack>
              </Stack>
            </form>
          </Paper>
        </Modal>

        {/* Snackbar para notificaciones */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert
            severity={snackbar.severity}
            sx={{
              backgroundColor: snackbar.severity === 'success' ? '#205a7b' : '#2b1818',
              color: '#ecf2f6'
            }}
            onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default Usuarios;
