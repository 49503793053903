import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  Modal,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Stack,
  Container,
  IconButton,
  Divider,
  Snackbar,
  Alert
} from '@mui/material';
import { useUser } from '../context/UserContext';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { createOrganization } from '../services/api';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#0b151b',
  boxShadow: 24,
  p: 4,
};

// Estilos personalizados para las celdas de la tabla
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: '#ecf2f6',
  borderBottom: '1px solid rgba(32, 90, 123, 0.5)',
  padding: '16px',
  '&.header': {
    backgroundColor: '#0b151b',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    height: '57px',
  }
}));

// Estilo para el contenedor de métricas
const StyledMetricBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#162d3a',
  padding: '12px',
  borderRadius: '8px',
  height: '100%',
}));

const Partners = () => {
  const { user, updateUser } = useUser();
  const [open, setOpen] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [formData, setFormData] = useState({
    organization: '',
    name: '',
  });
  const [saveSuccess, setSaveSuccess] = useState(false);

  // Cargar organizaciones iniciales
  useEffect(() => {
    if (user?.organizations) {
      setOrganizations(user.organizations);
    }
  }, [user?.organizations]);

  // Solo mostrar el botón si el usuario es admin o partner
  const canCreateUser = user?.rol === 'admin' || user?.rol === 'partner';

  const handleOpen = () => setOpen(true);
  const handleCloseModal = () => {
    setOpen(false);
    setFormData({ organization: '', name: '' });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await createOrganization(formData.name, formData.organization);
      
      // Actualizar el estado local con las nuevas organizaciones
      if (response?.organizations) {
        setOrganizations(response.organizations);
      }
      
      // Actualizar inmediatamente los datos globales del usuario
      if (response) {
        const updatedUserData = {
          ...user,
          ...response, // Aseguramos que todos los campos nuevos se actualicen
          organizations: response.organizations || user.organizations,
          products: response.products || user.products,
        };
        updateUser(updatedUserData);
        console.log('✅ Datos de usuario actualizados:', updatedUserData);
      }

      // Cerrar el modal y limpiar el formulario
      handleCloseModal();
      
      // Mostrar notificación de éxito
      setSaveSuccess(true);
    } catch (error) {
      console.error('Error al crear la organización:', error);
    }
  };

  const getAvailableParentOrganizations = () => {
    if (!user?.organizations) return [];
    
    // Si el usuario es partner, solo puede seleccionar su propia organización
    if (user.rol === 'partner') {
      return user.organizations.filter(org => org.code === user.organization);
    }
    
    // Si es admin, puede ver todas las organizaciones
    if (user.rol === 'admin') {
      return user.organizations;
    }
    
    return [];
  };

  return (
    <Container maxWidth={false}>
      <Box sx={{ p: 0 }}>
        {/* Header Box */}
        <Paper
          elevation={3}
          sx={{
            backgroundColor: '#0b151b',
            border: '1px solid #205a7b',
            borderRadius: 2,
            mb: 2,
            p: 2
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
            <Typography variant="h4" sx={{ color: '#ffffff', fontFamily: 'FSSinclair, sans-serif' }}>
              Partners
            </Typography>
            {canCreateUser && (
              <Button
                variant="contained"
                startIcon={<AddIcon sx={{ color: '#4aa6da' }} />}
                onClick={handleOpen}
                sx={{
                  backgroundColor: '#162d3a',
                  color: '#ecf2f6',
                  border: '1px solid #205a7b',
                  boxShadow: 'none',
                  '&:hover': {
                    backgroundColor: '#1a3a4a',
                    borderColor: '#4aa6da',
                    boxShadow: '0 0 10px rgba(74, 166, 218, 0.1)',
                  },
                  height: '40px',
                  textTransform: 'none',
                  fontSize: '0.9rem',
                  fontWeight: 500,
                  width: { xs: '100%', sm: 'auto' },
                  flex: { xs: '1 1 auto', sm: '0 0 auto' },
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  '& .MuiButton-startIcon': {
                    marginRight: 1,
                    '& svg': {
                      fontSize: '1.2rem'
                    }
                  }
                }}
              >
                Nuevo Cliente
              </Button>
            )}
          </Box>
          <Typography variant="body1" sx={{ color: '#96c3dd', fontFamily: 'FSSinclair, sans-serif' }}>
            Gestiona tus Partners. Dales de alta, baja o modifica sus datos.
          </Typography>
        </Paper>

        {/* Table Box */}
        <Paper
          elevation={3}
          sx={{
            backgroundColor: '#0b151b',
            border: '1px solid #205a7b',
            borderRadius: 2,
            overflow: 'hidden',
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell className="header">Código</StyledTableCell>
                  <StyledTableCell className="header">Nombre</StyledTableCell>
                  <StyledTableCell className="header">Organización Padre</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {organizations.map((org, index) => (
                  <TableRow
                    key={org.code}
                    sx={{
                      backgroundColor: index % 2 === 0 ? '#0b151b' : '#10202b',
                      '&:hover': {
                        backgroundColor: '#162d3a',
                      },
                      cursor: 'pointer',
                    }}
                  >
                    <StyledTableCell>{org.code}</StyledTableCell>
                    <StyledTableCell>{org.name}</StyledTableCell>
                    <StyledTableCell>{org.organization_tree || '-'}</StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        {/* Modal */}
        <Modal
          open={open}
          onClose={handleCloseModal}
          aria-labelledby="modal-nuevo-usuario"
        >
          <Paper sx={modalStyle}>
            {/* Header con título centrado */}
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center',
              position: 'relative',
              mb: 2
            }}>
              <Typography 
                variant="h6" 
                sx={{ 
                  color: '#ffffff', 
                  fontFamily: 'FSSinclair, sans-serif',
                  textAlign: 'center'
                }}
              >
                Agregar nuevo cliente
              </Typography>
            </Box>

            <Divider sx={{ 
              mb: 3, 
              backgroundColor: '#205a7b',
              opacity: 0.5
            }} />

            <form onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <FormControl fullWidth>
                  <InputLabel sx={{ color: '#96c3dd' }}>Organización</InputLabel>
                  <Select
                    name="organization"
                    value={formData.organization}
                    onChange={handleChange}
                    required
                    sx={{
                      color: '#ffffff',
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#205a7b'
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#4aa6da'
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#4aa6da'
                      }
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          bgcolor: '#0b151b',
                          '& .MuiMenuItem-root': {
                            color: '#ffffff'
                          },
                          '& .MuiMenuItem-root:hover': {
                            bgcolor: '#205a7b'
                          }
                        }
                      }
                    }}
                  >
                    {getAvailableParentOrganizations().map((org) => (
                      <MenuItem
                        key={org.code}
                        value={org.code}
                      >
                        {org.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  label="Nombre"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#205a7b',
                      },
                      '&:hover fieldset': {
                        borderColor: '#4aa6da',
                      },
                    },
                    '& .MuiInputLabel-root': {
                      color: '#96c3dd',
                    },
                    '& .MuiOutlinedInput-input': {
                      color: '#ffffff',
                    },
                  }}
                />
                <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
                  <Button
                    onClick={handleCloseModal}
                    variant="contained"
                    sx={{
                      backgroundColor: '#1e1e1e',
                      color: '#96c3dd',
                      border: '1px solid #333333',
                      '&:hover': {
                        backgroundColor: '#2a2a2a',
                        borderColor: '#444444',
                      },
                      flex: 1,
                      textTransform: 'none',
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    startIcon={<CheckIcon sx={{ color: '#4aa6da' }} />}
                    sx={{
                      backgroundColor: '#162d3a',
                      color: '#ecf2f6',
                      border: '1px solid #205a7b',
                      boxShadow: 'none',
                      '&:hover': {
                        backgroundColor: '#1a3a4a',
                        borderColor: '#4aa6da',
                        boxShadow: '0 0 10px rgba(74, 166, 218, 0.1)',
                      },
                      flex: 1,
                      textTransform: 'none',
                      fontSize: '0.9rem',
                      fontWeight: 500,
                      '& .MuiButton-startIcon': {
                        marginRight: 1,
                        '& svg': {
                          fontSize: '1.2rem'
                        }
                      }
                    }}
                  >
                    Confirmar
                  </Button>
                </Stack>
              </Stack>
            </form>
          </Paper>
        </Modal>

        {/* Snackbar de éxito */}
        <Snackbar 
          open={saveSuccess} 
          autoHideDuration={3000} 
          onClose={() => setSaveSuccess(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert severity="success" sx={{ backgroundColor: '#205a7b', color: '#ecf2f6' }}>
            Cliente creado exitosamente
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default Partners;
