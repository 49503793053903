import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Container,
  Tabs,
  Tab,
  IconButton,
  Snackbar,
  Alert,
  Button,
  CircularProgress
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSelectedChatbot } from '../context/SelectedChatbotContext';
import { getDemoData } from '../services/api';

const CodeBlock = ({ code, onCopy }) => (
  <Box sx={{ position: 'relative' }}>
    <IconButton
      onClick={onCopy}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        backgroundColor: '#162d3a',
        color: '#4aa6da',
        border: '1px solid #205a7b',
        '&:hover': {
          backgroundColor: '#1a3a4a',
          borderColor: '#4aa6da',
        },
      }}
    >
      <ContentCopyIcon />
    </IconButton>
    <Box
      sx={{
        backgroundColor: '#162d3a',
        p: 3,
        borderRadius: 1,
        border: '1px solid #205a7b',
        fontFamily: 'Courier New, monospace',
        fontSize: '14px',
        color: '#ecf2f6',
        overflowX: 'auto',
        mt: 2,
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-all'
      }}
    >
      {code}
    </Box>
  </Box>
);

const Implementation = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const { selectedChatbot } = useSelectedChatbot();
  const clientCode = selectedChatbot || 'your-client-code';
  const [demoLinks, setDemoLinks] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchDemoLinks = async () => {
      if (!selectedChatbot) return;
      
      try {
        setIsLoading(true);
        const data = await getDemoData(selectedChatbot);
        setDemoLinks(data);
      } catch (error) {
        console.error('Error al obtener los enlaces de las demos:', error);
        setSnackbar({
          open: true,
          message: 'Error al cargar los enlaces de las demos',
          severity: 'error'
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchDemoLinks();
  }, [selectedChatbot]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleCopyCode = (code) => {
    navigator.clipboard.writeText(code).then(() => {
      setSnackbar({
        open: true,
        message: 'Código copiado al portapapeles',
        severity: 'success'
      });
    }).catch(() => {
      setSnackbar({
        open: true,
        message: 'Error al copiar el código',
        severity: 'error'
      });
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const codeSnippets = {
    wordpress: `<!DOCTYPE html>
<html>
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<body>
    <div id="chatbot"></div>
    <script src="https://cdn.jsdelivr.net/npm/axios/dist/axios.min.js"></script>
    <script src="https://kit.fontawesome.com/e14478f995.js" crossorigin="anonymous"></script>
    <script>
        // versión: 1.0.3
        var CLIENTE = "${clientCode}";
        var RUTA = "https://overlord.chat";
        
        // Cargar datos del chatbot
        fetch(RUTA + '/api/v1/back/chatbot-data?id=' + CLIENTE)
            .then(response => response.json())
            .then(data => {
                (function() {
                    var script = document.createElement('script');
                    script.src = RUTA + '/chatbot-widget.js';
                    script.async = true;
                    script.onload = function() {
                        new Chatbot(document.getElementById('chatbot'), CLIENTE, data.welcome, data);
                    };
                    document.head.appendChild(script);
                })();
            })
            .catch(error => console.error('Error al cargar los datos del cliente:', error));
    </script>
</body>
</html>`,
    shopify: `<!-- Añade este código justo antes del cierre de </body> en tu archivo theme.liquid -->
<div id="chatbot"></div>
<script src="https://cdn.jsdelivr.net/npm/axios/dist/axios.min.js"></script>
<script src="https://kit.fontawesome.com/e14478f995.js" crossorigin="anonymous"></script>
<script>
    // versión: 1.0.3
    var CLIENTE = "${clientCode}";
    var RUTA = "https://overlord.chat";
    
    // Cargar datos del chatbot
    fetch(RUTA + '/api/v1/back/chatbot-data?id=' + CLIENTE)
        .then(response => response.json())
        .then(data => {
            (function() {
                var script = document.createElement('script');
                script.src = RUTA + '/chatbot-widget.js';
                script.async = true;
                script.onload = function() {
                    new Chatbot(document.getElementById('chatbot'), CLIENTE, data.welcome, data);
                };
                document.head.appendChild(script);
            })();
        })
        .catch(error => console.error('Error al cargar los datos del cliente:', error));
</script>`,
    html: `<!-- Añade este código justo antes del cierre de </body> en tu página HTML -->
<div id="chatbot"></div>
<script src="https://cdn.jsdelivr.net/npm/axios/dist/axios.min.js"></script>
<script src="https://kit.fontawesome.com/e14478f995.js" crossorigin="anonymous"></script>
<script>
    // versión: 1.0.3
    var CLIENTE = "${clientCode}";
    var RUTA = "https://overlord.chat";
    
    // Cargar datos del chatbot
    fetch(RUTA + '/api/v1/back/chatbot-data?id=' + CLIENTE)
        .then(response => response.json())
        .then(data => {
            (function() {
                var script = document.createElement('script');
                script.src = RUTA + '/chatbot-widget.js';
                script.async = true;
                script.onload = function() {
                    new Chatbot(document.getElementById('chatbot'), CLIENTE, data.welcome, data);
                };
                document.head.appendChild(script);
            })();
        })
        .catch(error => console.error('Error al cargar los datos del cliente:', error));
</script>`
  };

  return (
    <Container maxWidth={false}>
      <Box sx={{ py: 0 }}>
        <Paper
          elevation={3}
          sx={{
            backgroundColor: '#0b151b',
            border: '1px solid #205a7b',
            borderRadius: 2,
            p: 2,
            mb: 3
          }}
        >
          <Typography variant="h4" sx={{ color: '#ffffff', mb: 0, fontFamily: 'FSSinclair, sans-serif' }}>
            Implementación
          </Typography>
          <Typography variant="body1" sx={{ color: '#96c3dd', fontFamily: 'FSSinclair, sans-serif' }}>
            En esta sección puedes acceder a la implementación del asistente. También podrás visualizar las demostraciones.
          </Typography>
        </Paper>

        <Paper
          elevation={3}
          sx={{
            backgroundColor: '#0b151b',
            border: '1px solid #205a7b',
            borderRadius: 2,
            p: 2
          }}
        >
          <Typography variant="h6" sx={{ color: '#ffffff', mb: 2, fontFamily: 'FSSinclair, sans-serif' }}>
            Código de Implementación
          </Typography>
          
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            sx={{
              mb: 2,
              borderBottom: 1,
              borderColor: '#205a7b',
              '& .MuiTabs-indicator': {
                backgroundColor: '#4aa6da',
              },
              '& .MuiTab-root': {
                color: '#96c3dd',
                '&.Mui-selected': {
                  color: '#4aa6da',
                },
              },
            }}
          >
            <Tab label="WordPress" sx={{ fontFamily: 'FSSinclair, sans-serif' }} />
            <Tab label="Shopify" sx={{ fontFamily: 'FSSinclair, sans-serif' }} />
            <Tab label="HTML" sx={{ fontFamily: 'FSSinclair, sans-serif' }} />
          </Tabs>

          {selectedTab === 0 && (
            <>
              <Box sx={{ color: '#96c3dd', mb: 2 }}>
                <Typography variant="body2" gutterBottom sx={{ fontFamily: 'FSSinclair, sans-serif' }}>
                  Para WordPress con Elementor, sigue estos pasos:
                </Typography>
                <Typography variant="body2" component="div" sx={{ mt: 2, fontFamily: 'FSSinclair, sans-serif' }}>
                  1. Ve a Elementor y añade un nuevo widget "HTML"
                </Typography>
                <Typography variant="body2" component="div" sx={{ fontFamily: 'FSSinclair, sans-serif' }}>
                  2. Copia y pega el siguiente código completo en el widget
                </Typography>
                <Typography variant="body2" component="div" sx={{ fontFamily: 'FSSinclair, sans-serif' }}>
                  3. Guarda los cambios y publica la página
                </Typography>
              </Box>
              <CodeBlock 
                code={codeSnippets.wordpress} 
                onCopy={() => handleCopyCode(codeSnippets.wordpress)} 
              />
            </>
          )}

          {selectedTab === 1 && (
            <>
              <Box sx={{ color: '#96c3dd', mb: 2 }}>
                <Typography variant="body2" gutterBottom sx={{ fontFamily: 'FSSinclair, sans-serif' }}>
                  Para Shopify, sigue estos pasos:
                </Typography>
                <Typography variant="body2" component="div" sx={{ mt: 2, fontFamily: 'FSSinclair, sans-serif' }}>
                  1. Ve al panel de administración de tu tienda Shopify
                </Typography>
                <Typography variant="body2" component="div" sx={{ fontFamily: 'FSSinclair, sans-serif' }}>
                  2. Navega a Tienda online {'>'} Temas
                </Typography>
                <Typography variant="body2" component="div" sx={{ fontFamily: 'FSSinclair, sans-serif' }}>
                  3. Haz clic en "Acciones" {'>'} "Editar código"
                </Typography>
                <Typography variant="body2" component="div" sx={{ fontFamily: 'FSSinclair, sans-serif' }}>
                  4. Busca el archivo theme.liquid
                </Typography>
                <Typography variant="body2" component="div" sx={{ fontFamily: 'FSSinclair, sans-serif' }}>
                  5. Localiza la etiqueta {'</body>'} y pega el siguiente código justo antes
                </Typography>
              </Box>
              <CodeBlock 
                code={codeSnippets.shopify} 
                onCopy={() => handleCopyCode(codeSnippets.shopify)} 
              />
            </>
          )}

          {selectedTab === 2 && (
            <>
              <Box sx={{ color: '#96c3dd', mb: 2 }}>
                <Typography variant="body2" gutterBottom sx={{ fontFamily: 'FSSinclair, sans-serif' }}>
                  Para sitios web HTML, sigue estos pasos:
                </Typography>
                <Typography variant="body2" component="div" sx={{ mt: 2, fontFamily: 'FSSinclair, sans-serif' }}>
                  1. Localiza tu archivo public/index.html o el archivo HTML principal de tu sitio
                </Typography>
                <Typography variant="body2" component="div" sx={{ fontFamily: 'FSSinclair, sans-serif' }}>
                  2. Abre el archivo con tu editor de código preferido
                </Typography>
                <Typography variant="body2" component="div" sx={{ fontFamily: 'FSSinclair, sans-serif' }}>
                  3. Localiza la etiqueta {'</body>'} al final del archivo
                </Typography>
                <Typography variant="body2" component="div" sx={{ fontFamily: 'FSSinclair, sans-serif' }}>
                  4. Pega el siguiente código justo antes del cierre del body
                </Typography>
                <Typography variant="body2" component="div" sx={{ mt: 1, color: '#ff9800', fontFamily: 'FSSinclair, sans-serif' }}>
                  Nota: Si tienes múltiples páginas HTML, deberás añadir este código en cada una de ellas donde quieras que aparezca el chatbot.
                </Typography>
              </Box>
              <CodeBlock 
                code={codeSnippets.html} 
                onCopy={() => handleCopyCode(codeSnippets.html)} 
              />
            </>
          )}
        </Paper>

        {/* Nueva sección de Demos */}
        <Paper
          elevation={3}
          sx={{
            backgroundColor: '#0b151b',
            border: '1px solid #205a7b',
            borderRadius: 2,
            p: 2,
            mt: 3
          }}
        >
          <Typography variant="h6" sx={{ 
            color: '#ffffff', 
            mb: 1,
            fontSize: '1.5rem', 
            fontFamily: 'FSSinclair, sans-serif'
          }}>
            Demos
          </Typography>
          <Typography sx={{ 
            color: '#96c3dd',
            mb: 3,
            fontFamily: 'FSSinclair, sans-serif'
          }}>
            En este apartado podrás probar mediante nuestras demos casos reales de cómo quedará el asistente en el cliente
          </Typography>
          <Box sx={{ 
            display: 'flex',
            gap: 2,
            flexWrap: 'wrap'
          }}>
            {isLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', py: 2 }}>
                <CircularProgress sx={{ color: '#4aa6da' }} />
              </Box>
            ) : demoLinks?.hash ? (
              <>
                <Button
                  variant="contained"
                  href={`https://ai4logic.com/demo1.html?${selectedChatbot};${demoLinks.hash}`}
                  target="_blank"
                  sx={{
                    backgroundColor: '#162d3a',
                    color: '#ecf2f6',
                    border: '1px solid #205a7b',
                    boxShadow: 'none',
                    '&:hover': {
                      backgroundColor: '#1a3a4a',
                      borderColor: '#4aa6da',
                      boxShadow: '0 0 10px rgba(74, 166, 218, 0.1)',
                    },
                    height: '40px',
                    textTransform: 'none',
                    fontSize: '0.9rem',
                    fontWeight: 500,
                    width: { xs: '100%', sm: 'auto' },
                    flex: { xs: '1 1 auto', sm: '0 0 auto' },
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    '& .MuiButton-startIcon': {
                      marginRight: 1,
                      '& svg': {
                        fontSize: '1.2rem'
                      }
                    }
                  }}
                >
                  Demo mínima
                </Button>
                <Button
                  variant="contained"
                  href={`https://ai4logic.com/demo2.html?${selectedChatbot};${demoLinks.hash}`}
                  target="_blank"
                  sx={{
                    backgroundColor: '#162d3a',
                    color: '#ecf2f6',
                    border: '1px solid #205a7b',
                    boxShadow: 'none',
                    '&:hover': {
                      backgroundColor: '#1a3a4a',
                      borderColor: '#4aa6da',
                      boxShadow: '0 0 10px rgba(74, 166, 218, 0.1)',
                    },
                    height: '40px',
                    textTransform: 'none',
                    fontSize: '0.9rem',
                    fontWeight: 500,
                    width: { xs: '100%', sm: 'auto' },
                    flex: { xs: '1 1 auto', sm: '0 0 auto' },
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    '& .MuiButton-startIcon': {
                      marginRight: 1,
                      '& svg': {
                        fontSize: '1.2rem'
                      }
                    }
                  }}
                >
                  Demo con iframe
                </Button>
                <Button
                  variant="contained"
                  href={`https://ai4logic.com/demo3.html?${selectedChatbot};${demoLinks.hash}`}
                  target="_blank"
                  sx={{
                    backgroundColor: '#162d3a',
                    color: '#ecf2f6',
                    border: '1px solid #205a7b',
                    boxShadow: 'none',
                    '&:hover': {
                      backgroundColor: '#1a3a4a',
                      borderColor: '#4aa6da',
                      boxShadow: '0 0 10px rgba(74, 166, 218, 0.1)',
                    },
                    height: '40px',
                    textTransform: 'none',
                    fontSize: '0.9rem',
                    fontWeight: 500,
                    width: { xs: '100%', sm: 'auto' },
                    flex: { xs: '1 1 auto', sm: '0 0 auto' },
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    '& .MuiButton-startIcon': {
                      marginRight: 1,
                      '& svg': {
                        fontSize: '1.2rem'
                      }
                    }
                  }}
                >
                  Demo usando imagen
                </Button>
                <Button
                  variant="contained"
                  href={`https://ai4logic.com/demo4.html?${selectedChatbot};${demoLinks.hash}`}
                  target="_blank"
                  sx={{
                    backgroundColor: '#162d3a',
                    color: '#ecf2f6',
                    border: '1px solid #205a7b',
                    boxShadow: 'none',
                    '&:hover': {
                      backgroundColor: '#1a3a4a',
                      borderColor: '#4aa6da',
                      boxShadow: '0 0 10px rgba(74, 166, 218, 0.1)',
                    },
                    height: '40px',
                    textTransform: 'none',
                    fontSize: '0.9rem',
                    fontWeight: 500,
                    width: { xs: '100%', sm: 'auto' },
                    flex: { xs: '1 1 auto', sm: '0 0 auto' },
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    '& .MuiButton-startIcon': {
                      marginRight: 1,
                      '& svg': {
                        fontSize: '1.2rem'
                      }
                    }
                  }}
                >
                  Demo usando web local
                </Button>
              </>
            ) : (
              <Typography sx={{ color: '#96c3dd', fontStyle: 'italic' }}>
                No se pudieron cargar los enlaces de las demos
              </Typography>
            )}
          </Box>
        </Paper>
      </Box>

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={3000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          severity={snackbar.severity} 
          sx={{ 
            backgroundColor: snackbar.severity === 'success' ? '#205a7b' : '#2b1818',
            color: '#ecf2f6' 
          }}
          onClose={handleCloseSnackbar}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Implementation;
