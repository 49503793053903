import React, { useState, useMemo } from 'react';
import {
  Container,
  Box,
  Paper,
  TextField,
  Button,
  Typography,
  Alert,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Link } from 'react-router-dom';
import { requestPasswordRecovery } from '../services/api';

const RecuperarPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  // Validación de email usando expresión regular
  const isValidEmail = useMemo(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }, [email]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!email) {
      setError('Por favor, introduce tu correo electrónico');
      return;
    }

    if (!isValidEmail) {
      setError('Por favor, introduce un correo electrónico válido');
      return;
    }

    try {
      const data = await requestPasswordRecovery(email);
      
      if (data.result === 0) {
        setError('');
        setMessage('Correo enviado correctamente. Por favor, revisa tu bandeja de entrada.');
      } else {
        setError('El correo electrónico no está registrado o es inválido.');
      }
    } catch (error) {
      setError('El correo electrónico no está registrado o es inválido.');
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#0b151b',
        backgroundImage: 'linear-gradient(135deg, #0b151b 0%, #1a2c3d 100%)',
      }}
    >
      <Container component="main" maxWidth="xs">
        <Paper
          elevation={24}
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'rgba(11, 21, 27, 0.8)',
            backdropFilter: 'blur(10px)',
            border: '1px solid rgba(150, 195, 221, 0.12)',
            borderRadius: 3,
          }}
        >
          <Box
            sx={{
              width: 56,
              height: 56,
              borderRadius: '50%',
              backgroundColor: 'primary.main',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 2,
            }}
          >
            <LockOutlinedIcon sx={{ fontSize: 32, color: '#0b151b' }} />
          </Box>

          <Typography
            component="h1"
            variant="h5"
            sx={{
              mb: 3,
              background: 'linear-gradient(45deg, #96c3dd 30%, #4aa6da 90%)',
              backgroundClip: 'text',
              textFillColor: 'transparent',
              fontWeight: 700,
            }}
          >
            Recuperar Contraseña
          </Typography>

          <Typography
            variant="body1"
            sx={{
              mb: 1,
              color: '#96c3dd',
              textAlign: 'center'
            }}
          >
            Introduce tu correo electrónico y te enviaremos las instrucciones para restablecer tu contraseña.
          </Typography>

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Correo electrónico"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={email !== '' && !isValidEmail}
              helperText={email !== '' && !isValidEmail ? 'Correo electrónico no válido' : ''}
              sx={{ 
                input: { color: '#96c3dd' },
                '& .MuiFormLabel-root': {
                  color: '#96c3dd',
                  '&.Mui-focused': {
                    color: '#96c3dd',
                  },
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'rgba(150, 195, 221, 0.2)',
                    borderWidth: '1px',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(150, 195, 221, 0.3)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#96c3dd',
                    borderWidth: '1px',
                  },
                },
                '& .MuiFormHelperText-root': {
                  color: '#ff5252',
                },
              }}
            />

            {error && (
              <Alert 
                severity="error" 
                sx={{ 
                  mt: 2,
                  backgroundColor: 'rgba(211, 47, 47, 0.1)',
                  color: '#ff5252',
                }}
              >
                {error}
              </Alert>
            )}

            {message && (
              <Alert 
                severity="success" 
                sx={{ 
                  mt: 2,
                  backgroundColor: 'rgba(76, 175, 80, 0.1)',
                  color: '#69f0ae',
                }}
              >
                {message}
              </Alert>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={!isValidEmail}
              sx={{
                mt: 3,
                mb: 2,
                background: 'linear-gradient(45deg, #96c3dd 30%, #4aa6da 90%)',
                color: '#0b151b',
                fontWeight: 600,
                '&:hover': {
                  background: 'linear-gradient(45deg, #4aa6da 30%, #96c3dd 90%)',
                },
                '&.Mui-disabled': {
                  background: 'rgba(150, 195, 221, 0.2)',
                  color: 'rgba(150, 195, 221, 0.5)',
                }
              }}
            >
              Enviar
            </Button>

            <Box sx={{ textAlign: 'center' }}>
              <Link
                to="/login"
                style={{
                  color: '#96c3dd',
                  textDecoration: 'none',
                }}
              >
                Volver al inicio de sesión
              </Link>
            </Box>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default RecuperarPassword;
