export function HASH (input) {
    let hash1 = 0xABCDEF12; // Semilla 1 (32 bits)
    let hash2 = 0x34567890; // Semilla 2 (32 bits)
    const prime1 = 37;
    const prime2 = 41;

    for (let i = 0; i < input.length; i++) {
        let charCode = input.charCodeAt(i);

        hash1 = (hash1 ^ (charCode * prime1)) >>> 0; // XOR y mantener 32 bits
        hash1 = (hash1 * prime2) >>> 0; // Multiplicación y mantener 32 bits
        hash1 = ((hash1 >> 3) | (hash1 << 29)) >>> 0; // Rotación de bits derecha

        hash2 = (hash2 ^ (charCode * prime2)) >>> 0; // XOR con segunda semilla
        hash2 = (hash2 * prime1) >>> 0; // Multiplicación con otra constante
        hash2 = ((hash2 >> 5) | (hash2 << 27)) >>> 0; // Rotación de bits izquierda
    }

    // Convertimos los dos hash en hexadecimal y los combinamos para formar 16 caracteres
    let hexHash1 = hash1.toString(16).padStart(8, '0');
    let hexHash2 = hash2.toString(16).padStart(8, '0');

    return (hexHash1 + hexHash2).slice(-16);
} 
