import { createContext, useContext, useState, useEffect } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  // Cargar datos del usuario al iniciar
  useEffect(() => {
    try {
      const userData = JSON.parse(sessionStorage.getItem('user'));
      if (userData) {
        setUser(userData);
      }
    } catch (error) {
      sessionStorage.removeItem('user'); // Limpiar datos corruptos
    }
  }, []);

  // Actualizar datos del usuario
  const updateUser = (newUserData) => {
    if (!newUserData) {
      return;
    }

    try {
      setUser(newUserData);
      sessionStorage.setItem('user', JSON.stringify(newUserData));
    } catch (error) {
    }
  };

  // Limpiar datos del usuario
  const clearUser = () => {
    setUser(null);
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('sessionId');
  };

  return (
    <UserContext.Provider value={{ user, updateUser, clearUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser debe usarse dentro de un UserProvider');
  }
  return context;
};
