import React, { useState } from 'react';
import {
  Container,
  Box,
  Paper,
  TextField,
  Button,
  Typography,
  Alert,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate, useLocation } from 'react-router-dom';
import { resetPassword } from '../services/api';
import { HASH } from '../utils/helpers';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [countdown, setCountdown] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  // Obtener el linkcode de los parámetros de la URL
  const params = new URLSearchParams(location.search);
  const linkcode = params.get('linkcode');

  const validatePassword = (pass) => {
    // Verificar longitud mínima
    if (pass.length < 6) {
      setError('La contraseña debe tener al menos 6 caracteres');
      return false;
    }
    
    // Verificar que contenga letras
    if (!/[a-zA-Z]/.test(pass)) {
      setError('La contraseña debe contener al menos una letra');
      return false;
    }
    
    // Verificar que contenga números
    if (!/[0-9]/.test(pass)) {
      setError('La contraseña debe contener al menos un número');
      return false;
    }
    
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validaciones
    if (!password || !confirmPassword) {
      setError('Por favor, completa todos los campos');
      return;
    }

    if (!validatePassword(password)) {
      return; // El mensaje de error ya está establecido en validatePassword
    }

    if (password !== confirmPassword) {
      setError('Las contraseñas no coinciden');
      return;
    }

    if (!linkcode) {
      setError('Enlace de recuperación no válido');
      return;
    }

    try {
      const hashedPassword = HASH(password);
      await resetPassword(linkcode, hashedPassword);
      setError('');
      setMessage('Contraseña actualizada correctamente');
      
      // Iniciar cuenta regresiva
      setCountdown(3);
      const countdownInterval = setInterval(() => {
        setCountdown(prev => {
          if (prev <= 1) {
            clearInterval(countdownInterval);
            navigate('/login');
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } catch (error) {
      setError('Error al actualizar la contraseña. Por favor, inténtalo de nuevo');
      console.error('Error:', error);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#0b151b',
        backgroundImage: 'linear-gradient(135deg, #0b151b 0%, #1a2c3d 100%)',
      }}
    >
      <Container component="main" maxWidth="xs">
        <Paper
          elevation={24}
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'rgba(11, 21, 27, 0.8)',
            backdropFilter: 'blur(10px)',
            border: '1px solid rgba(150, 195, 221, 0.12)',
            borderRadius: 3,
          }}
        >
          <Box
            sx={{
              width: 56,
              height: 56,
              borderRadius: '50%',
              backgroundColor: 'primary.main',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 2,
            }}
          >
            <LockOutlinedIcon sx={{ fontSize: 32, color: '#0b151b' }} />
          </Box>

          <Typography
            component="h1"
            variant="h5"
            sx={{
              mb: 3,
              background: 'linear-gradient(45deg, #96c3dd 30%, #4aa6da 90%)',
              backgroundClip: 'text',
              textFillColor: 'transparent',
              fontWeight: 700,
            }}
          >
            Restablecer Contraseña
          </Typography>

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Nueva contraseña"
              type="password"
              id="password"
              autoComplete="new-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{ 
                input: { color: '#96c3dd' },
                '& .MuiFormLabel-root': {
                  color: '#96c3dd',
                  '&.Mui-focused': {
                    color: '#96c3dd',
                  },
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'rgba(150, 195, 221, 0.2)',
                    borderWidth: '1px',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(150, 195, 221, 0.3)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#96c3dd',
                    borderWidth: '1px',
                  },
                },
              }}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Repetir contraseña"
              type="password"
              id="confirmPassword"
              autoComplete="new-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              sx={{ 
                input: { color: '#96c3dd' },
                '& .MuiFormLabel-root': {
                  color: '#96c3dd',
                  '&.Mui-focused': {
                    color: '#96c3dd',
                  },
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'rgba(150, 195, 221, 0.2)',
                    borderWidth: '1px',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(150, 195, 221, 0.3)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#96c3dd',
                    borderWidth: '1px',
                  },
                },
              }}
            />

            {error && (
              <Alert 
                severity="error" 
                sx={{ 
                  mt: 2,
                  backgroundColor: 'rgba(211, 47, 47, 0.1)',
                  color: '#ff5252',
                }}
              >
                {error}
              </Alert>
            )}

            {message && (
              <Alert 
                severity="success" 
                sx={{ 
                  mt: 2,
                  backgroundColor: 'rgba(46, 125, 50, 0.1)',
                  color: '#4caf50',
                  '& .MuiAlert-icon': {
                    color: '#4caf50'
                  }
                }}
              >
                {message}
                {countdown !== null && (
                  <Typography sx={{ mt: 1 }}>
                    Redirigiendo al login en {countdown} segundos...
                  </Typography>
                )}
              </Alert>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                background: 'linear-gradient(45deg, #96c3dd 30%, #4aa6da 90%)',
                color: '#0b151b',
                fontWeight: 600,
                '&:hover': {
                  background: 'linear-gradient(45deg, #4aa6da 30%, #96c3dd 90%)',
                },
              }}
            >
              Cambiar Contraseña
            </Button>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default ResetPassword;
