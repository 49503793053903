import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Avatar,
  Button,
  Dialog,
  TextField,
  IconButton,
  Grid,
  Divider,
  Snackbar,
  Alert,
  Container
} from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { editUserName, changePassword } from '../services/api';
import { useUser } from '../context/UserContext';
import { HASH } from '../utils/helpers';

const Cuenta = () => {
  const { user, updateUser } = useUser();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [avatar, setAvatar] = useState('');
  const [editData, setEditData] = useState({
    name: '',
    email: ''
  });
  const [passwordData, setPasswordData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [error, setError] = useState(null);
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  useEffect(() => {
    if (user) {
      setEditData({
        name: user.name || '',
        email: user.email || ''
      });
      setAvatar(user.avatar || '');
    }
  }, [user]);

  const handleEditModalOpen = () => {
    setError(null);
    setOpenEditModal(true);
  };

  const handleEditModalClose = () => {
    setError(null);
    if (user) {
      setEditData({
        name: user.name || '',
        email: user.email || ''
      });
    }
    setOpenEditModal(false);
  };

  const handlePasswordModalOpen = () => {
    setError(null);
    setPasswordData({
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    });
    setOpenPasswordModal(true);
  };

  const handlePasswordModalClose = () => {
    setError(null);
    setPasswordData({
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    });
    setOpenPasswordModal(false);
  };

  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setAvatar(base64String);
        if (user) {
          const updatedUser = { ...user, avatar: base64String };
          updateUser(updatedUser);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEditDataChange = (event) => {
    const { name, value } = event.target;
    setEditData(prev => ({
      ...prev,
      [name]: value
    }));
    setError(null); // Limpiar error cuando el usuario empieza a escribir
  };

  const handlePasswordDataChange = (event) => {
    const { name, value } = event.target;
    setPasswordData(prev => ({
      ...prev,
      [name]: value
    }));
    setError(null);
  };

  const handleSaveChanges = async () => {
    try {
      if (!editData.name.trim()) {
        throw new Error('El nombre no puede estar vacío');
      }

      const updatedUser = await editUserName(editData.name);
      
      // Actualizar el contexto con los nuevos datos
      updateUser({
        ...user,
        name: editData.name
      });
      
      handleEditModalClose();
      setNotification({
        open: true,
        message: 'Cambios guardados correctamente',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error al guardar los cambios:', error);
      setError(error.message || 'Error al actualizar el nombre');
    }
  };

  const validatePassword = (pass) => {
    // Verificar longitud mínima
    if (pass.length < 6) {
      throw new Error('La contraseña nueva debe tener al menos 6 caracteres');
    }
    
    // Verificar que contenga letras
    if (!/[a-zA-Z]/.test(pass)) {
      throw new Error('La contraseña nueva debe contener al menos una letra');
    }
    
    // Verificar que contenga números
    if (!/[0-9]/.test(pass)) {
      throw new Error('La contraseña nueva debe contener al menos un número');
    }
    
    return true;
  };

  const handleChangePassword = async () => {
    try {
      if (!passwordData.oldPassword || !passwordData.newPassword || !passwordData.confirmPassword) {
        throw new Error('Todos los campos son obligatorios');
      }

      if (passwordData.newPassword !== passwordData.confirmPassword) {
        throw new Error('Las contraseñas nuevas no coinciden');
      }

      // Validar la nueva contraseña
      validatePassword(passwordData.newPassword);

      const hashedOldPassword = HASH(passwordData.oldPassword);
      const hashedNewPassword = HASH(passwordData.newPassword);

      try {
        await changePassword(hashedOldPassword, hashedNewPassword);
        handlePasswordModalClose();
        setNotification({
          open: true,
          message: 'Contraseña actualizada correctamente',
          severity: 'success'
        });
      } catch (apiError) {
        // Si hay un error de red o el servidor no responde, significa que la contraseña actual es incorrecta
        throw new Error('La contraseña actual es incorrecta');
      }
    } catch (error) {
      console.error('Error al cambiar la contraseña:', error);
      setError(error.message || 'Error al cambiar la contraseña');
    }
  };

  const handleCloseNotification = () => {
    setNotification(prev => ({ ...prev, open: false }));
  };

  // Si no hay usuario, mostrar mensaje de carga
  if (!user) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography variant="h6" sx={{ color: '#96c3dd' }}>
          Cargando datos del usuario...
        </Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth={false}>
      <Box sx={{ py: 0 }}>
        <Paper
          elevation={3}
          sx={{
            backgroundColor: '#0b151b',
            border: '1px solid #205a7b',
            borderRadius: 2,
            p: 2,
            mb:2
          }}
        >
          <Typography variant="h4" sx={{ color: '#ffffff', mb: 0, fontFamily: 'FSSinclair, sans-serif' }}>
            Mi Cuenta
          </Typography>
        </Paper>

        <Paper
          elevation={3}
          sx={{
            backgroundColor: '#0b151b',
            border: '1px solid #205a7b',
            borderRadius: 2,
            p: 3
          }}
        >
          <Grid container spacing={3}>
            {/* Perfil Principal */}
            <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Box sx={{ position: 'relative' }}>
                <Avatar
                  src={avatar}
                  sx={{
                    width: 150,
                    height: 150,
                    border: '3px solid #205a7b'
                  }}
                />
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  sx={{
                    position: 'absolute',
                    bottom: 5,
                    right: 5,
                    backgroundColor: '#0b151b',
                    border: '1px solid #205a7b',
                    '&:hover': { backgroundColor: '#4aa6da' }
                  }}
                >
                  <input hidden accept="image/*" type="file" onChange={handleAvatarChange} />
                  <PhotoCamera />
                </IconButton>
              </Box>
              <Typography variant="h6" sx={{ mt: 2, color: '#ffffff', fontFamily: 'FSSinclair, sans-serif' }}>
                {user?.name}
              </Typography>
              <Typography variant="body1" sx={{ color: '#96c3dd', fontFamily: 'FSSinclair, sans-serif' }}>
                {user?.email}
              </Typography>
            </Grid>

            {/* Información y Acciones */}
            <Grid item xs={12} md={8}>
              <Box sx={{ pl: { md: 3 } }}>
                <Typography variant="h6" sx={{ color: '#96c3dd', mb: 2, fontFamily: 'FSSinclair, sans-serif' }}>
                  Detalles de la Cuenta
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="subtitle2" sx={{ color: '#96c3dd', fontFamily: 'FSSinclair, sans-serif' }}>
                      Nombre
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#ffffff', fontFamily: 'FSSinclair, sans-serif' }}>
                      {user?.name}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Typography variant="subtitle2" sx={{ color: '#96c3dd', fontFamily: 'FSSinclair, sans-serif' }}>
                      Email
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#ffffff', fontFamily: 'FSSinclair, sans-serif' }}>
                      {user?.email}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Typography variant="subtitle2" sx={{ color: '#96c3dd', fontFamily: 'FSSinclair, sans-serif' }}>
                      Organización
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#ffffff', fontFamily: 'FSSinclair, sans-serif' }}>
                      {user?.organizations?.find(org => org.code === user.organization)?.name || '-'}
                    </Typography>
                  </Grid>
                </Grid>

                <Divider sx={{ my: 3, backgroundColor: '#205a7b' }} />

                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Button
                    variant="contained"
                    startIcon={<EditIcon sx={{ color: '#4aa6da' }}/>}
                    onClick={handleEditModalOpen}
                    sx={{
                      backgroundColor: '#162d3a',
                      color: '#ecf2f6',
                      border: '1px solid #205a7b',
                      boxShadow: 'none',
                      '&:hover': {
                        backgroundColor: '#1a3a4a',
                        borderColor: '#4aa6da',
                        boxShadow: '0 0 10px rgba(74, 166, 218, 0.1)',
                      },
                      height: '40px',
                      textTransform: 'none',
                      fontSize: '0.9rem',
                      fontWeight: 500,
                      width: { xs: '100%', sm: 'auto' },
                      flex: { xs: '1 1 auto', sm: '0 0 auto' },
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      '& .MuiButton-startIcon': {
                        marginRight: 1,
                        '& svg': {
                          fontSize: '1.2rem'
                        }
                      }
                    }}
                  >
                    Editar Perfil
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<LockIcon sx={{ color: '#4aa6da' }}/>}
                    onClick={handlePasswordModalOpen}
                    sx={{
                      backgroundColor: '#162d3a',
                      color: '#ecf2f6',
                      border: '1px solid #205a7b',
                      boxShadow: 'none',
                      '&:hover': {
                        backgroundColor: '#1a3a4a',
                        borderColor: '#4aa6da',
                        boxShadow: '0 0 10px rgba(74, 166, 218, 0.1)',
                      },
                      height: '40px',
                      textTransform: 'none',
                      fontSize: '0.9rem',
                      fontWeight: 500,
                      width: { xs: '100%', sm: 'auto' },
                      flex: { xs: '1 1 auto', sm: '0 0 auto' },
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      '& .MuiButton-startIcon': {
                        marginRight: 1,
                        '& svg': {
                          fontSize: '1.2rem'
                        }
                      }
                    }}
                  >
                    Cambiar Contraseña
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>

      {/* Modales */}
      <Dialog
        open={openEditModal}
        onClose={handleEditModalClose}
        PaperProps={{
          sx: {
            backgroundColor: '#0b151b',
            border: '1px solid #205a7b',
            borderRadius: 2,
            p: 2,
            maxWidth: '800px',
            maxHeight: '90vh',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '8px'
            },
            '&::-webkit-scrollbar-track': {
              background: '#0b151b'
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#205a7b',
              borderRadius: '4px',
              '&:hover': {
                background: '#4aa6da'
              }
            }
          }
        }}
      >
        <Typography variant="h6" sx={{ color: '#ffffff', mb: 3, fontFamily: 'FSSinclair, sans-serif' }}>
          Editar Perfil
        </Typography>
        <Box component="form" noValidate onSubmit={(e) => { e.preventDefault(); handleSaveChanges(); }}>
          <TextField
            fullWidth
            label="Nombre"
            name="name"
            value={editData.name}
            onChange={handleEditDataChange}
            margin="normal"
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#205a7b',
                },
                '&:hover fieldset': {
                  borderColor: '#4aa6da',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#4aa6da',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#96c3dd',
              },
              '& .MuiInputBase-input': {
                color: '#ffffff',
              },
            }}
          />
          <TextField
            fullWidth
            label="Correo Electrónico"
            name="email"
            value={editData.email}
            disabled
            margin="normal"
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#205a7b',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#96c3dd',
              },
              '& .MuiInputBase-input': {
                color: '#ffffff',
              },
            }}
          />

          <Button
            variant="contained"
            startIcon={<LockIcon />}
            onClick={handlePasswordModalOpen}
            sx={{
              mt: 2,
              backgroundColor: '#205a7b',
              color: '#ffffff',
              '&:hover': { backgroundColor: '#4aa6da' },
              textTransform: 'none',
              fontWeight: 'bold',
              fontFamily: 'FSSinclair, sans-serif'
            }}
          >
            Cambiar Contraseña
          </Button>

          {error && (
            <Typography variant="body2" sx={{ color: '#ff6b6b', mt: 1, mb: 1 }}>
              {error}
            </Typography>
          )}
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button
              onClick={handleEditModalClose}
              sx={{
                color: '#96c3dd',
                '&:hover': { color: '#ffffff' },
                textTransform: 'none',
                fontFamily: 'FSSinclair, sans-serif'
              }}
            >
              Cancelar
            </Button>
            <Button
              onClick={handleSaveChanges}
              variant="contained"
              sx={{
                backgroundColor: '#205a7b',
                color: '#ffffff',
                '&:hover': { backgroundColor: '#4aa6da' },
                textTransform: 'none',
                fontWeight: 'bold',
                fontFamily: 'FSSinclair, sans-serif'
              }}
            >
              Guardar
            </Button>
          </Box>
        </Box>
      </Dialog>

      <Dialog
        open={openPasswordModal}
        onClose={handlePasswordModalClose}
        PaperProps={{
          sx: {
            backgroundColor: '#0b151b',
            border: '1px solid #205a7b',
            borderRadius: 2,
            p: 2,
            maxWidth: '800px',
            maxHeight: '90vh',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '8px'
            },
            '&::-webkit-scrollbar-track': {
              background: '#0b151b'
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#205a7b',
              borderRadius: '4px',
              '&:hover': {
                background: '#4aa6da'
              }
            }
          }
        }}
      >
        <Typography variant="h6" sx={{ color: '#ffffff', mb: 3, fontFamily: 'FSSinclair, sans-serif' }}>
          Cambiar Contraseña
        </Typography>
        <Box component="form" noValidate onSubmit={(e) => { e.preventDefault(); handleChangePassword(); }}>
          <TextField
            fullWidth
            type="password"
            label="Contraseña Actual"
            name="oldPassword"
            value={passwordData.oldPassword}
            onChange={handlePasswordDataChange}
            margin="normal"
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#205a7b',
                },
                '&:hover fieldset': {
                  borderColor: '#4aa6da',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#4aa6da',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#96c3dd',
              },
              '& .MuiInputBase-input': {
                color: '#ffffff',
              },
            }}
          />
          <TextField
            fullWidth
            type="password"
            label="Nueva Contraseña"
            name="newPassword"
            value={passwordData.newPassword}
            onChange={handlePasswordDataChange}
            margin="normal"
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#205a7b',
                },
                '&:hover fieldset': {
                  borderColor: '#4aa6da',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#4aa6da',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#96c3dd',
              },
              '& .MuiInputBase-input': {
                color: '#ffffff',
              },
            }}
          />
          <TextField
            fullWidth
            type="password"
            label="Confirmar Nueva Contraseña"
            name="confirmPassword"
            value={passwordData.confirmPassword}
            onChange={handlePasswordDataChange}
            margin="normal"
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#205a7b',
                },
                '&:hover fieldset': {
                  borderColor: '#4aa6da',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#4aa6da',
                },
              },
              '& .MuiInputLabel-root': {
                color: '#96c3dd',
              },
              '& .MuiInputBase-input': {
                color: '#ffffff',
              },
            }}
          />
          {error && (
            <Alert 
              severity="error" 
              sx={{ 
                mt: 2,
                backgroundColor: 'rgba(211, 47, 47, 0.1)',
                color: '#ff6b6b',
                '& .MuiAlert-icon': {
                  color: '#ff6b6b'
                },
                border: '1px solid rgba(211, 47, 47, 0.3)',
                borderRadius: 1
              }}
            >
              {error}
            </Alert>
          )}
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button
              onClick={handlePasswordModalClose}
              sx={{
                color: '#96c3dd',
                '&:hover': { color: '#ffffff' },
                textTransform: 'none',
                fontFamily: 'FSSinclair, sans-serif'
              }}
            >
              Cancelar
            </Button>
            <Button
              onClick={handleChangePassword}
              variant="contained"
              sx={{
                backgroundColor: '#205a7b',
                color: '#ffffff',
                '&:hover': { backgroundColor: '#4aa6da' },
                textTransform: 'none',
                fontWeight: 'bold',
                fontFamily: 'FSSinclair, sans-serif'
              }}
            >
              Guardar
            </Button>
          </Box>
        </Box>
      </Dialog>

      {/* Notificación */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notification.severity}
          sx={{
            width: '100%',
            backgroundColor: notification.severity === 'success' ? '#205a7b' : '#ff6b6b',
            color: '#ffffff',
            '& .MuiAlert-icon': {
              color: '#ffffff'
            }
          }}
        >
          {notification.message}
        </Alert>
      </Snackbar>

    </Container>
  );
};

export default Cuenta;
