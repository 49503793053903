import React, { useState, useEffect } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
  Avatar,
  Divider,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ListItemButton
} from '@mui/material';
import { Home, Chat, Analytics, AutoGraph, Menu as MenuIcon, Logout, Settings as SettingsIcon, School, Code } from '@mui/icons-material';
import HandshakeIcon from '@mui/icons-material/Handshake';
import GroupIcon from '@mui/icons-material/Group';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../context/UserContext';
import { logout } from '../../services/api';
import dayjs from 'dayjs';
import { useDateRange } from '../../context/DateContext';
import { useSelectedChatbot } from '../../context/SelectedChatbotContext';

const drawerWidth = 240;

const menuItems = [
  {
    category: 'Seguimiento',
    items: [
      { text: 'Estadísticas', icon: <Analytics />, path: '/' },
      { text: 'Conversaciones', icon: <Chat />, path: '/conversations' },
      { text: 'Analíticas', icon: <AutoGraph />, path: '/analytics' }
    ]
  },
  {
    category: 'Asistente',
    items: [
      { text: 'Entrenamiento', icon: <School />, path: '/training' },
      { text: 'Parámetros', icon: <SettingsIcon />, path: '/settings' },
      { text: 'Implementación', icon: <Code />, path: '/implementation' }
    ]
  },
  {
    category: 'Ajustes',
    items: [
      { text: 'Partners', icon: <HandshakeIcon />, path: '/partners' },
      { text: 'Usuarios', icon: <GroupIcon />, path: '/users' },
      { text: 'Cuenta', icon: <ManageAccountsIcon />, path: '/account' }
    ]
  }
];

const Sidebar = ({ mobileOpen, handleDrawerToggle }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { user, clearUser } = useUser();
  const { setStartDate, setEndDate } = useDateRange();
  const { selectedChatbot, setSelectedChatbot, availableAssistants, clearChatbotData } = useSelectedChatbot();

  useEffect(() => {
    try {
      // Si hay productos disponibles y no hay uno seleccionado, usar el primero
      if (user && user.products?.length > 0 && !selectedChatbot) {
        setSelectedChatbot(user.products[0].code);
      }
    } catch (error) {
      console.error('Error al cargar datos del usuario:', error);
    }
  }, [setSelectedChatbot, selectedChatbot, user]);

  const handleLogout = async () => {
    try {
      // Intentamos hacer logout en el backend
      await logout();
    } catch (error) {
      console.error('Error en logout del backend:', error);
      // Continuamos con el logout local aunque falle el backend
    } finally {
      // Limpiamos los datos locales de forma segura
      if (typeof clearChatbotData === 'function') {
        clearChatbotData();
      }
      clearUser();
      navigate('/login');
    }
  };

  const handleNavigation = (path) => {
    navigate(path);
    if (mobileOpen) {
      handleDrawerToggle();
    }
  };

  const handleChatbotChange = (event) => {
    setSelectedChatbot(event.target.value);
  };

  // Obtener el nombre del asistente seleccionado
  const selectedAssistantName = availableAssistants.find(
    a => a.assistant === selectedChatbot
  )?.name || 'Seleccione un asistente';

  const drawer = (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#0b151b',
        position: 'relative'
      }}
    >
      {/* User Profile Section */}
      <Box
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Avatar
            src={user?.avatar}
            sx={{
              width: 40,
              height: 40,
            }}
          />
          <Box sx={{ flexGrow: 1, minWidth: 0 }}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 500,
                fontFamily: 'FSSinclair, sans-serif',
                color: '#ffffff',
                wordWrap: 'break-word',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                lineHeight: '1.2em',
                maxHeight: '2.4em',
                mb: 0.5
              }}
            >
              {user?.name}
            </Typography>
            <Typography 
              variant="caption" 
              sx={{ 
                color: '#4aa6da',
                fontFamily: 'FSSinclair, sans-serif',
                display: 'block',
                fontSize: '0.75rem',
                lineHeight: 1
              }}
            >
              {user?.organizations?.find(org => org.code === user.organization)?.name || ''}
            </Typography>
          </Box>
          <Tooltip title="Cerrar sesión">
            <IconButton
              onClick={handleLogout}
              size="small"
              sx={{
                color: '#96c3dd',
                '&:hover': {
                  color: '#4aa6da'
                }
              }}
            >
              <Logout />
            </IconButton>
          </Tooltip>
        </Box>

        {/* Selector de chatbot */}
        {user && (
          <FormControl 
            fullWidth 
            variant="outlined"
            size="small"
            sx={{
              mt: 2,
              px: 2,
              '& .MuiOutlinedInput-root': {
                color: '#ffffff',
                fontFamily: 'FSSinclair, sans-serif',
                '& fieldset': {
                  borderColor: '#205a7b',
                },
                '&:hover fieldset': {
                  borderColor: '#2c7aa8',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#2c7aa8',
                }
              },
              '& .MuiInputLabel-root': {
                color: '#96c3dd',
                backgroundColor: '#0b151b',
                padding: '0 8px',
                marginLeft: '-4px',
                fontFamily: 'FSSinclair, sans-serif'
              },
              '& .MuiInputLabel-shrink': {
                transform: 'translate(14px, -9px) scale(0.75)',
              }
            }}
          >
            <InputLabel id="select-chatbot-label">Cliente</InputLabel>
            <Select
              labelId="select-chatbot-label"
              value={selectedChatbot || ''}
              onChange={handleChatbotChange}
              label="Producto"
              MenuProps={{
                PaperProps: {
                  sx: {
                    bgcolor: '#0b151b',
                    '& .MuiMenuItem-root': {
                      color: '#ffffff',
                      fontFamily: 'FSSinclair, sans-serif'
                    },
                    '& .MuiMenuItem-root:hover': {
                      bgcolor: '#205a7b'
                    }
                  }
                }
              }}
            >
              {availableAssistants.length > 0 ? (
                availableAssistants.map((assistant) => (
                  <MenuItem 
                    key={assistant.assistant} 
                    value={assistant.assistant}
                    sx={{
                      color: '#ecf2f6',
                      '&:hover': {
                        backgroundColor: 'rgba(74, 166, 218, 0.1)'
                      },
                      '&.Mui-selected': {
                        backgroundColor: 'rgba(74, 166, 218, 0.2)',
                        '&:hover': {
                          backgroundColor: 'rgba(74, 166, 218, 0.3)',
                        }
                      }
                    }}
                  >
                    {assistant.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="" disabled>
                  No hay asistentes disponibles
                </MenuItem>
              )}
            </Select>
          </FormControl>
        )}
      </Box>

      <Typography
        variant="h5"
        sx={{
          fontWeight: 600,
          padding: '10px 20px 10px',
          borderTop: '1px solid #205a7b',
          borderBottom: '1px solid #205a7b',
          textAlign: 'left',
          letterSpacing: '0.5px',
          fontFamily: 'FSSinclair, sans-serif',
          transition: 'all 0.3s ease-in-out',
          cursor: 'pointer',
          '&:hover': {
            textShadow: '0 0 20px rgba(255, 165, 0, 0.8)',
            transform: 'scale(1.00)',
            letterSpacing: '2px',
          },
          textShadow: '0 0 10px rgba(74, 166, 218, 0.3)',
        }}
      >
        <span style={{ color: 'orange', textShadow: '0 0 10px rgba(255, 165, 0, 0.7)' }}>4</span>
        <span style={{ color: 'white' }}>Logic</span>
        <span style={{ color: 'orange', textShadow: '0 0 10px rgba(255, 165, 0, 0.7)' }}>One</span>
      </Typography>

      {/* Menu Items */}
      <List sx={{ width: '100%', p: 1 }}>
        {menuItems.map((category) => {
          // Filtrar los items según el rol del usuario
          const filteredItems = category.items.filter(item => {
            // Los admin pueden ver todo
            if (user?.rol === 'admin') return true;
            
            // Partners pueden ver todo excepto Partners
            if (user?.rol === 'partner') {
              return true;
            }
            
            // Users solo pueden ver lo básico (ni Partners ni Gestión)
            if (user?.rol === 'user') {
              return item.text !== 'Partners' && item.text !== 'Usuarios';
            }
            
            return false; // Si no tiene rol, no ve nada
          });

          // Si no hay items después del filtrado, no mostrar la categoría
          if (filteredItems.length === 0) return null;

          return (
            <Box key={category.category} sx={{ mb: 2 }}>
              <ListItem
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  borderRadius: '4px',
                  mb: 1
                }}
              >
                <ListItemText
                  primary={category.category}
                  primaryTypographyProps={{
                    fontWeight: 600,
                    fontSize: '0.9rem',
                    fontFamily: 'FSSinclair, sans-serif',
                    color: 'text.secondary'
                  }}
                />
              </ListItem>
              <List component="div" disablePadding>
                {filteredItems.map((item) => (
                  <ListItem
                    button
                    key={item.text}
                    onClick={() => handleNavigation(item.path)}
                    sx={{
                      pl: 3,
                      borderRadius: '4px',
                      mb: 0.5,
                      '&:hover': {
                        backgroundColor: 'rgba(25, 118, 210, 0.08)'
                      }
                    }}
                  >
                    <ListItemIcon sx={{
                      minWidth: '40px',
                      color: 'text.secondary'
                    }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      primaryTypographyProps={{
                        fontSize: '0.875rem',
                        fontFamily: 'FSSinclair, sans-serif',
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          );
        })}
      </List>

      <Box sx={{ flex: 1 }} /> {/* Spacer para empujar el texto de versión al final */}

      {/* Versión */}
      <Typography
        variant="caption"
        sx={{
          color: 'rgba(150, 195, 221, 0.5)',
          fontFamily: 'FSSinclair, sans-serif',
          fontSize: '0.7rem',
          textAlign: 'center',
          padding: '10px 20px',
          width: '100%',
          marginTop: 'auto',
          backgroundColor: '#0b151b'
        }}
      >
        V 1.2.1 - AI4Logic
      </Typography>
    </Box>
  );

  return (
    <>
      {/* Botón de menú para móviles */}
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{
          position: 'fixed',
          top: 16,
          left: 16,
          zIndex: 1200,
          display: { xs: 'block', sm: 'none' },
          color: '#ecf2f6',
          backgroundColor: '#1a2730',
          boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
          '&:hover': {
            backgroundColor: '#243441',
          }
        }}
      >
        <MenuIcon />
      </IconButton>

      {/* Drawer temporal para móviles */}
      <Drawer
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            backgroundColor: '#0b151b',
            color: '#ecf2f6',
            borderRight: '1px solid rgba(150, 195, 221, 0.12)'
          },
        }}
      >
        {drawer}
      </Drawer>

      {/* Drawer permanente para pantallas grandes */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: '#0b151b',
            borderRight: '1px solid rgba(150, 195, 221, 0.12)'
          }
        }}
        open
      >
        {drawer}
      </Drawer>
    </>
  );
};

export default Sidebar;