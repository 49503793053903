import React, { useState, useEffect } from 'react';
import { fetchData } from '../services/api';
import {
  Box,
  Typography,
  Paper,
  Container,
  Grid,
  CircularProgress,
  useTheme,
  useMediaQuery,
  IconButton,
  Tooltip as MuiTooltip,
} from '@mui/material';
import DateRangePicker from '../components/DateRangePicker';
import dayjs from 'dayjs';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
  LineChart,
  Line,
} from 'recharts';
import { useDateRange } from '../context/DateContext';
import { useSelectedChatbot } from '../context/SelectedChatbotContext';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const FIXED_COLORS = {
  RESOLUTION: '#1976d2', // Azul
  URGENCY: '#2e7d32',   // Verde
  RELEVANCE: '#ed6c02', // Naranja
  VALORATION: '#9c27b0' // Morado
};

const getColorByValoration = () => FIXED_COLORS.VALORATION;
const getActiveColorByValoration = () => FIXED_COLORS.VALORATION;
const getColorByUrgency = () => FIXED_COLORS.URGENCY;
const getActiveColorByUrgency = () => FIXED_COLORS.URGENCY;

const cardStyles = {
  elevation: 3,
  p: 2,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  backgroundColor: '#0b151b',
  border: '1px solid #205a7b',
  borderRadius: 2,
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 4px 20px rgba(150, 195, 221, 0.15)',
    borderColor: '#4aa6da',
  },
};

const chartContainerStyles = {
  p: 2,
  backgroundColor: '#0b151b',
  border: '1px solid #205a7b',
  borderRadius: 2,
};

const getComparisonText = (startDate, endDate) => {
  const diffDays = endDate.diff(startDate, 'day') + 1;
  const isStartOfMonth = startDate.date() === 1;
  const isEndOfMonth = endDate.date() === endDate.daysInMonth();
  const isFullMonth = isStartOfMonth && isEndOfMonth && diffDays >= 28;

  // Caso especial para periodos comunes
  if (diffDays === 1) {
    return 'desde ayer';
  } else if (diffDays === 7) {
    return 'desde la semana pasada';
  } else if (diffDays === 14) {
    return 'desde hace dos semanas';
  } else if (isFullMonth) {
    return 'desde el mes pasado';
  } else if (diffDays <= 7) {
    return 'desde la semana pasada';
  } else if (diffDays <= 14) {
    return 'desde hace dos semanas';
  } else if (diffDays <= 31) {
    return 'desde el mes pasado';
  }

  // Para periodos más largos
  const months = Math.floor(diffDays / 30);
  if (months > 1) {
    return `desde hace ${months} meses`;
  }

  return 'desde el periodo anterior';
};

const StatCard = ({ title, value, description, growth, startDate, endDate }) => (
  <Paper sx={cardStyles}>
    <Typography variant="h6" sx={{ color: '#96c3dd' }}>
      {title}
    </Typography>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, mb: 1 }}>
      <Typography variant="h4" sx={{ color: '#ecf2f6', display: 'flex', alignItems: 'baseline' }}>
        {typeof value === 'string' && value.includes('(') ? (
          <>
            {value.split('(')[0]}
            <Typography component="span" sx={{ fontSize: '0.6em', ml: 1, color: '#fff' }}>
              ({value.split('(')[1]}
            </Typography>
          </>
        ) : value}
      </Typography>
      {growth !== undefined && (
        <Typography
          variant="body2"
          sx={{
            color: growth >= 0 ? '#4ade80' : '#ef4444',
            fontSize: '0.875rem',
            lineHeight: 1.2
          }}
        >
          {growth >= 0 ? '+' : ''}{growth}% {getComparisonText(startDate, endDate)}
        </Typography>
      )}
    </Box>
    <Typography variant="body2" sx={{ color: '#96c3dd' }}>
      {description}
    </Typography>
  </Paper>
);

const ChartCard = ({ title, children }) => (
  <Paper sx={chartContainerStyles}>
    {title}
    {children}
  </Paper>
);

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [data, setData] = useState([]);
  const [previousData, setPreviousData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { startDate, setStartDate, endDate, setEndDate } = useDateRange();
  const { selectedChatbot } = useSelectedChatbot();

  useEffect(() => {
    if (selectedChatbot) {
      fetchDataForDateRange();
    }
  }, [startDate, endDate, selectedChatbot]);

  const fetchDataForDateRange = async () => {
    setLoading(true);
    setError(null);
    try {
      if (!selectedChatbot) {
        setError('No hay asistente seleccionado');
        return;
      }

      // Fetch current period data
      const currentData = await fetchData(
        1, // tipo
        selectedChatbot,
        startDate.format('YYYYMMDD'),
        endDate.format('YYYYMMDD')
      );

      // Calculate previous period
      const diffDays = endDate.diff(startDate, 'day') + 1;
      const previousStart = startDate.subtract(diffDays, 'day');
      const previousEnd = startDate.subtract(1, 'day');

      // Fetch previous period data
      const previousData = await fetchData(
        1, // tipo
        selectedChatbot,
        previousStart.format('YYYYMMDD'),
        previousEnd.format('YYYYMMDD')
      );

      setData(currentData || []);
      setPreviousData(previousData || []);
    } catch (err) {
      setError('Error al cargar los datos');
      console.error(err);
      setData([]);
      setPreviousData([]);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  const safeData = data || [];
  const safePreviousData = previousData || [];

  // Calculate statistics
  const totalConversations = safeData.length;
  const previousTotalConversations = safePreviousData.length;
  const conversationsGrowth = previousTotalConversations ?
    ((totalConversations - previousTotalConversations) / previousTotalConversations * 100).toFixed(1) : 0;

  const totalMessages = safeData.reduce((sum, chat) => sum + (chat.nummsj || 0), 0);
  const previousTotalMessages = safePreviousData.reduce((sum, chat) => sum + (chat.nummsj || 0), 0);
  const messagesGrowth = previousTotalMessages ?
    ((totalMessages - previousTotalMessages) / previousTotalMessages * 100).toFixed(1) : 0;
  
  const avgMessages = safeData.length ?
    (totalMessages / safeData.length).toFixed(1) : 0;

  const avgDuration = safeData.length ?
    (safeData.reduce((sum, chat) => sum + (chat.duracion || 0), 0) / safeData.length).toFixed(1) : 0;
  const previousAvgDuration = safePreviousData.length ?
    (safePreviousData.reduce((sum, chat) => sum + (chat.duracion || 0), 0) / safePreviousData.length).toFixed(1) : 0;
  const durationGrowth = previousAvgDuration ?
    ((avgDuration - previousAvgDuration) / previousAvgDuration * 100).toFixed(1) : 0;

  // Calculate unique users
  const uniqueUsers = new Set(safeData.map(chat => chat.usuario)).size;
  const previousUniqueUsers = new Set(safePreviousData.map(chat => chat.usuario)).size;
  const usersGrowth = previousUniqueUsers ?
    ((uniqueUsers - previousUniqueUsers) / previousUniqueUsers * 100).toFixed(1) : 0;

  // Calculate users growth trend
  const usersByDay = {};
  const allUsers = new Set();
  const newUsersByDay = {};

  // Ordenar los chats por fecha
  const sortedChats = [...safeData].sort((a, b) => a.fecha.localeCompare(b.fecha));

  // Calcular usuarios nuevos por día
  sortedChats.forEach(chat => {
    const date = chat.fecha;
    if (date) {
      if (!usersByDay[date]) {
        usersByDay[date] = new Set();
        newUsersByDay[date] = new Set();
      }
      usersByDay[date].add(chat.usuario);

      // Si es un usuario que no habíamos visto antes, es nuevo
      if (!allUsers.has(chat.usuario)) {
        newUsersByDay[date].add(chat.usuario);
        allUsers.add(chat.usuario);
      }
    }
  });

  const usersGrowthData = Object.entries(newUsersByDay)
    .sort(([dateA], [dateB]) => dateA.localeCompare(dateB))
    .map(([date, users]) => ({
      date: `${date.substring(6, 8)}/${date.substring(4, 6)}`,
      users: users.size
    }));

  // Calculate conversations growth trend
  const conversationsByDay = {};
  safeData.forEach(chat => {
    const date = chat.fecha;
    if (date) {
      if (!conversationsByDay[date]) {
        conversationsByDay[date] = 0;
      }
      conversationsByDay[date]++;
    }
  });

  const conversationsGrowthData = Object.entries(conversationsByDay)
    .sort(([dateA], [dateB]) => dateA.localeCompare(dateB))
    .map(([date, count]) => ({
      date: `${date.substring(6, 8)}/${date.substring(4, 6)}`,
      conversations: count
    }));

  // Calculate weekly distribution
  const weeklyDistribution = Array(7).fill(0);
  const dayNames = ['L', 'M', 'X', 'J', 'V', 'S', 'D'];
  safeData.forEach(chat => {
    if (chat.fecha) {
      const date = dayjs(chat.fecha, 'YYYYMMDD');
      const dayOfWeek = date.day();
      // Adjust for dayjs where 0 is Sunday, we want 0 to be Monday
      const adjustedDay = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
      weeklyDistribution[adjustedDay]++;
    }
  });

  const weeklyData = weeklyDistribution.map((count, index) => ({
    day: dayNames[index],
    count,
  }));

  // Calculate hourly distribution
  const hourlyDistribution = Array(24).fill(0);
  safeData.forEach(chat => {
    if (chat.hora) {
      const hour = parseInt(chat.hora.substring(0, 2));
      if (!isNaN(hour) && hour >= 0 && hour < 24) {
        hourlyDistribution[hour]++;
      }
    }
  });

  const hourlyData = hourlyDistribution.map((count, hour) => ({
    hour: `${hour.toString().padStart(2, '0')}:00`,
    count,
  }));

  return (
    <Container maxWidth={false} sx={{ py: 0, backgroundColor: '#0b151b', minHeight: '100vh' }}>
      <Paper
        elevation={3}
        sx={{
          p: { xs: 2, sm: 2 },
          mb: { xs: 2, sm: 3 },
          borderRadius: 2,
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: isMobile ? 'stretch' : 'center',
          gap: 2,
          backgroundColor: '#0b151b',
          border: '1px solid #205a7b',
        }}
      >
        <Box>
          <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#ecf2f6', mb: 0, fontFamily: 'FSSinclair, sans-serif', }}>
            Estadísticas
          </Typography>
          <Typography variant="body1" sx={{ color: '#96c3dd', fontFamily: 'FSSinclair, sans-serif', }}>
            Panel de control con métricas y análisis de las conversaciones del 4LogicOne
          </Typography>
        </Box>
        <Box>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
          />
        </Box>
      </Paper>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <>
          {/* Primera fila de Stats Cards */}
          <Grid container spacing={3} sx={{ mb: 3 }}>
            <Grid item xs={12} sm={6} md={3}>
              <StatCard
                title="Total Conversaciones"
                value={totalConversations}
                description="Número total de conversaciones"
                growth={conversationsGrowth}
                startDate={startDate}
                endDate={endDate}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StatCard
                title="Total Usuarios"
                value={uniqueUsers}
                description="Número total de usuarios"
                growth={usersGrowth}
                startDate={startDate}
                endDate={endDate}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StatCard
                title="Total Mensajes"
                value={`${totalMessages} (${avgMessages}/conv)`}
                description="Total de mensajes y media por conversación"
                growth={messagesGrowth}
                startDate={startDate}
                endDate={endDate}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StatCard
                title="Duración Media"
                value={`${avgDuration} seg`}
                description="Duración promedio de conversaciones"
                growth={durationGrowth}
                startDate={startDate}
                endDate={endDate}
              />
            </Grid>
          </Grid>

          {/* Segunda fila de Stats Cards */}
          <Grid container spacing={3} sx={{ mb: 3 }}>
            {/* 1. Conversaciones */}
            <Grid item xs={12} sm={6} md={3}>
              <ChartCard title={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                  <Typography variant="h6" sx={{ color: '#96c3dd', flexGrow: 1 }}>
                    Conversaciones
                  </Typography>
                  <MuiTooltip
                    title={
                      <Typography sx={{ fontSize: '0.9rem', p: 1 }}>
                        Número total de conversaciones que han tenido lugar cada día en el periodo seleccionado
                      </Typography>
                    }
                    placement="right"
                  >
                    <IconButton size="small" sx={{ color: '#96c3dd', p: 0 }}>
                      <HelpOutlineIcon sx={{ fontSize: '1rem' }} />
                    </IconButton>
                  </MuiTooltip>
                </Box>
              }>
                <Box sx={{ width: '100%', height: 100 }}>
                  <ResponsiveContainer>
                    <LineChart
                      data={conversationsGrowthData}
                      margin={{ top: 5, right: 10, left: -10, bottom: 0 }}
                    >
                      <CartesianGrid strokeDasharray="0 0" stroke="#205a7b" />
                      <XAxis
                        dataKey="date"
                        stroke="#96c3dd"
                        tick={{ fill: '#96c3dd', fontSize: 10 }}
                        axisLine={{ stroke: '#205a7b' }}
                      />
                      <YAxis
                        stroke="#96c3dd"
                        tick={{ fill: '#96c3dd', fontSize: 10 }}
                        axisLine={{ stroke: '#205a7b' }}
                        width={25}
                      />
                      <Tooltip
                        contentStyle={{
                          backgroundColor: '#0b151b',
                          border: `1px solid ${FIXED_COLORS.RESOLUTION}`,
                          borderRadius: '4px',
                          padding: '8px'
                        }}
                        labelStyle={{ color: '#96c3dd' }}
                        formatter={(value) => [`${value}`, 'Conversaciones']}
                        labelFormatter={(label) => `Fecha: ${label}`}
                      />
                      <Line
                        type="monotone"
                        dataKey="conversations"
                        stroke={FIXED_COLORS.RESOLUTION}
                        strokeWidth={2}
                        dot={false}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Box>
              </ChartCard>
            </Grid>

            {/* 2. Usuarios Nuevos */}
            <Grid item xs={12} sm={6} md={3}>
              <ChartCard title={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                  <Typography variant="h6" sx={{ color: '#96c3dd', flexGrow: 1 }}>
                    Usuarios Nuevos
                  </Typography>
                  <MuiTooltip
                    title={
                      <Typography sx={{ fontSize: '0.9rem', p: 1 }}>
                        Número de usuarios nuevos que interactúan con el sistema por primera vez cada día
                      </Typography>
                    }
                    placement="right"
                  >
                    <IconButton size="small" sx={{ color: '#96c3dd', p: 0 }}>
                      <HelpOutlineIcon sx={{ fontSize: '1rem' }} />
                    </IconButton>
                  </MuiTooltip>
                </Box>
              }>
                <Box sx={{ width: '100%', height: 100 }}>
                  <ResponsiveContainer>
                    <LineChart
                      data={usersGrowthData}
                      margin={{ top: 5, right: 10, left: -10, bottom: 0 }}
                    >
                      <CartesianGrid strokeDasharray="0 0" stroke="#205a7b" />
                      <XAxis
                        dataKey="date"
                        stroke="#96c3dd"
                        tick={{ fill: '#96c3dd', fontSize: 10 }}
                        axisLine={{ stroke: '#205a7b' }}
                      />
                      <YAxis
                        stroke="#96c3dd"
                        tick={{ fill: '#96c3dd', fontSize: 10 }}
                        axisLine={{ stroke: '#205a7b' }}
                        width={25}
                      />
                      <Tooltip
                        contentStyle={{
                          backgroundColor: '#0b151b',
                          border: `1px solid ${FIXED_COLORS.RESOLUTION}`,
                          borderRadius: '4px',
                          padding: '8px'
                        }}
                        labelStyle={{ color: '#96c3dd' }}
                        formatter={(value, name) => [`${value}`, 'Nuevos usuarios']}
                        labelFormatter={(label) => `Fecha: ${label}`}
                      />
                      <Line
                        type="monotone"
                        dataKey="users"
                        stroke={FIXED_COLORS.RESOLUTION}
                        strokeWidth={2}
                        dot={false}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Box>
              </ChartCard>
            </Grid>

            {/* 3. Distribución semanal */}
            <Grid item xs={12} sm={6} md={3}>
              <ChartCard title={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                  <Typography variant="h6" sx={{ color: '#96c3dd', flexGrow: 1 }}>
                    Distribución semanal
                  </Typography>
                  <MuiTooltip
                    title={
                      <Typography sx={{ fontSize: '0.9rem', p: 1 }}>
                        Distribución de las conversaciones por día de la semana, mostrando los patrones de actividad de lunes a domingo
                      </Typography>
                    }
                    placement="right"
                  >
                    <IconButton size="small" sx={{ color: '#96c3dd', p: 0 }}>
                      <HelpOutlineIcon sx={{ fontSize: '1rem' }} />
                    </IconButton>
                  </MuiTooltip>
                </Box>
              }>
                <Box sx={{ width: '100%', height: 100 }}>
                  <ResponsiveContainer>
                    <LineChart
                      data={weeklyData}
                      margin={{ top: 5, right: 10, left: -10, bottom: 0 }}
                    >
                      <CartesianGrid strokeDasharray="0 0" stroke="#205a7b" />
                      <XAxis
                        dataKey="day"
                        stroke="#96c3dd"
                        tick={{ fill: '#96c3dd', fontSize: 10 }}
                        axisLine={{ stroke: '#205a7b' }}
                      />
                      <YAxis
                        stroke="#96c3dd"
                        tick={{ fill: '#96c3dd', fontSize: 10 }}
                        axisLine={{ stroke: '#205a7b' }}
                        width={25}
                      />
                      <Tooltip
                        contentStyle={{
                          backgroundColor: '#0b151b',
                          border: `1px solid ${FIXED_COLORS.RESOLUTION}`,
                          borderRadius: '4px',
                          padding: '8px'
                        }}
                        labelStyle={{ color: '#96c3dd' }}
                        formatter={(value) => [`${value}`, 'Conversaciones']}
                        labelFormatter={(label) => `Día: ${label}`}
                      />
                      <Line
                        type="monotone"
                        dataKey="count"
                        stroke={FIXED_COLORS.RESOLUTION}
                        strokeWidth={2}
                        dot={false}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Box>
              </ChartCard>
            </Grid>

            {/* 4. Distribución por Hora */}
            <Grid item xs={12} sm={6} md={3}>
              <ChartCard title={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                  <Typography variant="h6" sx={{ color: '#96c3dd', flexGrow: 1 }}>
                    Distribución por Hora
                  </Typography>
                  <MuiTooltip
                    title={
                      <Typography sx={{ fontSize: '0.9rem', p: 1 }}>
                        Distribución de las conversaciones a lo largo del día, mostrando las horas con mayor y menor actividad
                      </Typography>
                    }
                    placement="right"
                  >
                    <IconButton size="small" sx={{ color: '#96c3dd', p: 0 }}>
                      <HelpOutlineIcon sx={{ fontSize: '1rem' }} />
                    </IconButton>
                  </MuiTooltip>
                </Box>
              }>
                <Box sx={{ width: '100%', height: 100 }}>
                  <ResponsiveContainer>
                    <LineChart
                      data={hourlyData}
                      margin={{ top: 5, right: 10, left: -10, bottom: 0 }}
                    >
                      <CartesianGrid strokeDasharray="0 0" stroke="#205a7b" />
                      <XAxis
                        dataKey="hour"
                        stroke="#96c3dd"
                        tick={{ fill: '#96c3dd', fontSize: 10 }}
                        axisLine={{ stroke: '#205a7b' }}
                      />
                      <YAxis
                        stroke="#96c3dd"
                        tick={{ fill: '#96c3dd', fontSize: 10 }}
                        axisLine={{ stroke: '#205a7b' }}
                        width={25}
                      />
                      <Tooltip
                        contentStyle={{
                          backgroundColor: '#0b151b',
                          border: `1px solid ${FIXED_COLORS.RESOLUTION}`,
                          borderRadius: '4px',
                          padding: '8px'
                        }}
                        labelStyle={{ color: '#96c3dd' }}
                        formatter={(value) => [`${value}`, 'Conversaciones']}
                        labelFormatter={(label) => `Hora: ${label}`}
                      />
                      <Line
                        type="monotone"
                        dataKey="count"
                        stroke={FIXED_COLORS.RESOLUTION}
                        strokeWidth={2}
                        dot={false}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Box>
              </ChartCard>
            </Grid>
          </Grid>

          {/* Gráficos de métricas en una fila */}
          <Grid container spacing={3} sx={{ mb: 3 }}>
            <Grid item xs={12} sm={6} md={3}>
              <ChartCard title={
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography variant="h6" sx={{ color: '#96c3dd'}}>Resolución</Typography>
                    <MuiTooltip
                      title={
                        <Typography sx={{ fontSize: '0.9rem', p: 1 }}>
                          Es un valor numérico subjetivo del 0 al 10 indicando si se ha resuelto la consulta o incidencia o pregunta. El valor de 10 indica que se ha resuelto, por ejemplo, si el asistente ha contestado totalmente la pregunta del usuario.
                        </Typography>
                      }
                      placement="right"
                    >
                      <IconButton size="small" sx={{ color: '#96c3dd', p: 0 }}>
                        <HelpOutlineIcon sx={{ fontSize: '1rem' }} />
                      </IconButton>
                    </MuiTooltip>
                  </Box>
                  <Typography variant="subtitle1" sx={{ color: '#96c3dd' }}>Media: {safeData.length ? (safeData.reduce((sum, chat) => sum + (chat.resuelto || 0), 0) / safeData.length).toFixed(1) : 0}</Typography>
                </Box>
              }>
                <ResponsiveContainer width="100%" height={200}>
                  <BarChart data={Array.from({ length: 11 }, (_, i) => ({
                    nivel: `${i}`,
                    nivel_num: i,
                    cantidad: safeData.filter(chat => chat.resuelto === i).length
                  }))}
                    margin={{ top: 5, right: 0, left: -40, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" stroke="#205a7b" />
                    <XAxis dataKey="nivel" stroke="#96c3dd" tick={{ fontSize: 10 }} />
                    <YAxis stroke="#96c3dd" tick={{ fontSize: 10 }} />
                    <Tooltip
                      cursor={{ fill: FIXED_COLORS.RESOLUTION, opacity: 0.1 }}
                      contentStyle={{
                        backgroundColor: '#0b151b',
                        border: `1px solid ${FIXED_COLORS.RESOLUTION}`,
                        borderRadius: '4px',
                        padding: '8px'
                      }}
                      labelStyle={{ color: '#96c3dd' }}
                      formatter={(value, name) => [`${value}`, 'Cantidad']}
                      labelFormatter={(label) => `Resolución: ${label}`}
                    />
                    <Bar dataKey="cantidad" fill={FIXED_COLORS.RESOLUTION}>
                      {Array.from({ length: 11 }, (_, i) => (
                        <Cell key={`cell-${i}`} fill={FIXED_COLORS.RESOLUTION} />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </ChartCard>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <ChartCard title={
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography variant="h6" sx={{ color: '#96c3dd'}}>Urgencia</Typography>
                    <MuiTooltip
                      title={
                        <Typography sx={{ fontSize: '0.9rem', p: 1 }}>
                          Es un valor numérico subjetivo del 0 al 10 indicando la urgencia de la conversación o solicitud. Si es muy urgente se dará el máximo valor de 10 o si no un valor más bajo, hasta 0, que es valor mínimo.
                        </Typography>
                      }
                      placement="right"
                    >
                      <IconButton size="small" sx={{ color: '#96c3dd', p: 0 }}>
                        <HelpOutlineIcon sx={{ fontSize: '1rem' }} />
                      </IconButton>
                    </MuiTooltip>
                  </Box>
                  <Typography variant="subtitle1" sx={{ color: '#96c3dd' }}>Media: {safeData.length ? (safeData.reduce((sum, chat) => sum + (chat.urgencia || 0), 0) / safeData.length).toFixed(1) : 0}</Typography>
                </Box>
              }>
                <ResponsiveContainer width="100%" height={200}>
                  <BarChart
                    data={Array.from({ length: 11 }, (_, i) => ({
                      nivel: `${i}`,
                      nivel_num: i,
                      cantidad: safeData.filter(chat => chat.urgencia === i).length
                    }))}
                    margin={{ top: 5, right: 0, left: -40, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="0 0" stroke="#205a7b" />
                    <XAxis
                      dataKey="nivel"
                      stroke="#96c3dd"
                      tick={{ fill: '#96c3dd', fontSize: 10 }}
                      axisLine={{ stroke: '#205a7b' }}
                    />
                    <YAxis
                      stroke="#96c3dd"
                      tick={{ fill: '#96c3dd', fontSize: 10 }}
                      axisLine={{ stroke: '#205a7b' }}
                    />
                    <Tooltip
                      cursor={{ fill: FIXED_COLORS.URGENCY, opacity: 0.1 }}
                      contentStyle={{
                        backgroundColor: '#0b151b',
                        border: `1px solid ${FIXED_COLORS.URGENCY}`,
                        borderRadius: '4px',
                        padding: '8px'
                      }}
                      labelStyle={{ color: '#96c3dd' }}
                      formatter={(value, name) => [`${value}`, 'Cantidad']}
                      labelFormatter={(label) => `Urgencia: ${label}`}
                    />
                    <Bar dataKey="cantidad" fill={FIXED_COLORS.URGENCY}>
                      {Array.from({ length: 11 }, (_, i) => (
                        <Cell key={`cell-${i}`} fill={FIXED_COLORS.URGENCY} />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </ChartCard>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <ChartCard title={
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography variant="h6" sx={{ color: '#96c3dd'}}>Relevancia</Typography>
                    <MuiTooltip
                      title={
                        <Typography sx={{ fontSize: '0.9rem', p: 1 }}>
                          Es un valor numérico subjetivo del 0 al 10 indicando si la conversación es relevante para la empresa. Por ejemplo, si es de tipo test o solo del tipo saludos sin más tendría una relevancia de 0.
                        </Typography>
                      }
                      placement="right"
                    >
                      <IconButton size="small" sx={{ color: '#96c3dd', p: 0 }}>
                        <HelpOutlineIcon sx={{ fontSize: '1rem' }} />
                      </IconButton>
                    </MuiTooltip>
                  </Box>
                  <Typography variant="subtitle1" sx={{ color: '#96c3dd' }}>Media: {safeData.length ? (safeData.reduce((sum, chat) => sum + (chat.relevancia || 0), 0) / safeData.length).toFixed(1) : 0}</Typography>
                </Box>
              }>
                <ResponsiveContainer width="100%" height={200}>
                  <BarChart
                    data={Array.from({ length: 11 }, (_, i) => ({
                      nivel: `${i}`,
                      nivel_num: i,
                      cantidad: safeData.filter(chat => chat.relevancia === i).length
                    }))}
                    margin={{ top: 5, right: 0, left: -40, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="0 0" stroke="#205a7b" />
                    <XAxis
                      dataKey="nivel"
                      stroke="#96c3dd"
                      tick={{ fill: '#96c3dd', fontSize: 10 }}
                      axisLine={{ stroke: '#205a7b' }}
                    />
                    <YAxis
                      stroke="#96c3dd"
                      tick={{ fill: '#96c3dd', fontSize: 10 }}
                      axisLine={{ stroke: '#205a7b' }}
                    />
                    <Tooltip
                      cursor={{ fill: FIXED_COLORS.RELEVANCE, opacity: 0.1 }}
                      contentStyle={{
                        backgroundColor: '#0b151b',
                        border: `1px solid ${FIXED_COLORS.RELEVANCE}`,
                        borderRadius: '4px',
                        padding: '8px'
                      }}
                      labelStyle={{ color: '#96c3dd' }}
                      formatter={(value, name) => [`${value}`, 'Cantidad']}
                      labelFormatter={(label) => `Relevancia: ${label}`}
                    />
                    <Bar dataKey="cantidad" fill={FIXED_COLORS.RELEVANCE}>
                      {Array.from({ length: 11 }, (_, i) => (
                        <Cell key={`cell-${i}`} fill={FIXED_COLORS.RELEVANCE} />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </ChartCard>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <ChartCard title={
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography variant="h6" sx={{ color: '#96c3dd'}}>Valoración</Typography>
                    <MuiTooltip
                      title={
                        <Typography sx={{ fontSize: '0.9rem', p: 1 }}>
                          Es un valor numérico subjetivo del 0 al 10 indicando si la conversación es valiosa comercialmente (máximo valor de 10) o no (valor mínimo de 0).
                        </Typography>
                      }
                      placement="right"
                    >
                      <IconButton size="small" sx={{ color: '#96c3dd', p: 0 }}>
                        <HelpOutlineIcon sx={{ fontSize: '1rem' }} />
                      </IconButton>
                    </MuiTooltip>
                  </Box>
                  <Typography variant="subtitle1" sx={{ color: '#96c3dd' }}>Media: {safeData.length ? (safeData.reduce((sum, chat) => sum + (chat.valoracion || 0), 0) / safeData.length).toFixed(1) : 0}</Typography>
                </Box>
              }>
                <ResponsiveContainer width="100%" height={200}>
                  <BarChart
                    data={Array.from({ length: 11 }, (_, i) => ({
                      nivel: `${i}`,
                      nivel_num: i,
                      cantidad: safeData.filter(chat => chat.valoracion === i).length
                    }))}
                    margin={{ top: 5, right: 0, left: -40, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="0 0" stroke="#205a7b" />
                    <XAxis
                      dataKey="nivel"
                      stroke="#96c3dd"
                      tick={{ fill: '#96c3dd', fontSize: 10 }}
                      axisLine={{ stroke: '#205a7b' }}
                    />
                    <YAxis
                      stroke="#96c3dd"
                      tick={{ fill: '#96c3dd', fontSize: 10 }}
                      axisLine={{ stroke: '#205a7b' }}
                    />
                    <Tooltip
                      cursor={{ fill: FIXED_COLORS.VALORATION, opacity: 0.1 }}
                      contentStyle={{
                        backgroundColor: '#0b151b',
                        border: `1px solid ${FIXED_COLORS.VALORATION}`,
                        borderRadius: '4px',
                        padding: '8px'
                      }}
                      labelStyle={{ color: '#96c3dd' }}
                      formatter={(value, name) => [`${value}`, 'Cantidad']}
                      labelFormatter={(label) => `Valoración: ${label}`}
                    />
                    <Bar dataKey="cantidad" fill={FIXED_COLORS.VALORATION}>
                      {Array.from({ length: 11 }, (_, i) => (
                        <Cell key={`cell-${i}`} fill={FIXED_COLORS.VALORATION} />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </ChartCard>
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );

};

export default Home;
